<template>
    <div>    
        <eod-dialog v-if="isCreateCompanyDialogVisible" v-model="isCreateCompanyDialogVisible" width="1200" title="Bedrijf toevoegen" icon="mdi-plus" iconColor="primary" okText="Toevoegen" @okAction="saveCompany" :loading="isCreateCompanyDialogLoading">
                    <eod-company-detail class="mt-4" compact v-model="createCompany"></eod-company-detail>
                </eod-dialog>
                <v-row>
                    <v-col cols="12" :md="isAdvanced?8:12">
                        <eod-card :outlined="compact">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <eod-text-field dense :value="value.name" @input="val => updateProperty('name', val)" :rules="[val => $validator.required(val, 'Naam')]" label="Naam*" required outlined
                                            hide-details></eod-text-field>
                                    </v-col>
                                    <v-col>
                                        <eod-text-field dense :value="value.description" @input="val => updateProperty('description', val)" label="Omschrijving" outlined
                                            hide-details></eod-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <eod-object-autocomplete label="Bedrijf*" required :rules="[val => $validator.required(val, 'Bedrijf')]" v-if="value" endpoint="companies" :value="value.company?value.company.id:null" @input="val => updateProperty('company', {id: val})">
                                            <template v-slot:append-item>
                                                <v-divider></v-divider>
                                                <v-list-item dense @click="isCreateCompanyDialogVisible = true">
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-plus</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Bedrijf toevoegen</v-list-item-title>
                                                                <v-list-item-subtitle>Voeg een bedrijf toe wanneer u deze
                                                                    niet in de lijst vindt.</v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                            </template>
                                        </eod-object-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!isAdvanced" @click="isAdvanced = true" text small>Geavanceerd <v-icon right small>mdi-chevron-down</v-icon></v-btn>
                </v-row>
                            </v-card-text>
                        </eod-card>
                        <eod-card v-if="isAdvanced" id="card_contacts" title="Contacten" class="mt-4" :outlined="compact">
                                    <eod-contacts header-inset flat :roles="settings.roles.contacts" v-model="value.users" :show-role="false"></eod-contacts>
                                </eod-card>
                        <eod-card v-if="isAdvanced" id="card_employees" title="Medewerkers" class="mt-4" :outlined="compact">
                            <eod-contacts header-inset flat :roles="settings.roles.collaborators" v-model="value.users" :show-role="false"></eod-contacts>
                        </eod-card>
                    </v-col>
                    <v-col cols="12" md="4" v-if="isAdvanced">
                        <eod-card class="mb-4" v-if="sharepointURLTemplate" title="Koppelingen" :outlined="compact">
                            <v-card-text>
                                <v-row class="mt-2">
                                    <v-col cols="4" lg="2" xl="1">
                                        <a target="_blank" :href="$helper.replaceTags(sharepointURLTemplate, {project: value})"><v-img
                                                :src="require('@/assets/img/integrations/sharepoint.svg')" contain /></a>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions></v-card-actions>
                        </eod-card>
                        <eod-card id="card_locations" class="mb-4" :outlined="compact">
                            <template v-slot:header-title>
                                <v-badge color="grey" :content="value.locations?''+value.locations.length:'0'" inline>Locaties</v-badge>
                            </template>
                            <template v-slot:header-actions>
                                <v-btn id="btn_edit_locations" @click="editLocations" small icon><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                            </template>
                            <eod-locations-map v-if="value.locations && value.locations.length > 0" ratio="1.4" :value="value.locations" readonly></eod-locations-map>
                            <v-list dense>
                                <v-list-item @click="editLocations" v-for="(location, locationIndex) in value.locations" :key="locationIndex">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ location.name }}</v-list-item-title>
                                        <v-list-item-subtitle>{{location.streetAddress}}, {{location.postalCode}} {{location.locality}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <eod-dialog icon="mdi-map" iconColor="primary" @okAction="value.locations = locations;isLocationsDialogVisible=false;" title="Locaties" okText="Toepassen" width="100%" max-width="1200" v-if="isLocationsDialogVisible" v-model="isLocationsDialogVisible">
                                <template v-slot:headerActions>
                                    <v-btn id="btn_create_location" @click="$refs.locationsList.addItem()" depressed color="primary" rounded><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>
                                </template>
                                <eod-locations hide-add ref="locationsList" hide-search v-model="locations"></eod-locations>
                            </eod-dialog>
                        </eod-card>
                        <eod-card :outlined="compact" title="Tags" v-if="$eod.getOrganizationSetting('tags.listId') && $eod.getOrganizationSetting('tags.listId').value">
                            <v-card-text>
                                <eod-tags deletable addable v-model="value.tags"></eod-tags>
                            </v-card-text>
                        </eod-card>
                        <eod-card :outlined="compact" class="mt-4" title="Favoriete media" v-if="value && value.id">
                            <template v-slot:header-actions>
                                <v-btn @click="tab=2" small icon><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                            </template>
                            <v-card-text>
                                <eod-storage-objects-carousel :filters="[{ config:{type:'property', filter:'projectId', operator:'='}, result:{id: value.id} }, {config:{type:'property',filter:'isFavorite',operator:'='}, result:{id:'1'}}]"></eod-storage-objects-carousel>
                            </v-card-text>
                        </eod-card>
                    </v-col>
                </v-row>
                </div>
</template>
<script>
import eodTags from './eod-tags.vue';
import eodContacts from './eod-contacts.vue';
import eodLocations from './eod-locations.vue';
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodObjectsTreeviewDetail from './eod-objects-treeview-detail.vue';
import eodLocationsMap from './eod-locations-map.vue';
import eodStorageObjectsCarousel from './eod-storage-objects-carousel.vue';
import eodCompanyDetail from './eod-company-detail.vue';
import Company from './../models/company.js';
import Service from './../models/service.js';

export default {
    name: 'eod-project-detail',
    components:{
        eodContacts,
        eodCompanyDetail,
        eodStorageObjectsCarousel,
        eodObjectsTreeviewDetail,
        eodObjectAutocomplete,
        eodLocationsMap,
        eodTags,
        eodLocations
    },
    props:{
        value: {
            type: Object,
            default: () => ({})
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            isAdvanced: false,
            isCreateCompanyDialogVisible: false,
            isCreateCompanyDialogLoading: false,
            createCompany: {},
            isLocationsDialogVisible: false,
            locations: [],
            settings: require('./../classes/settings.js'),
            sharepointURLTemplate: null,
        }
    },
    created(){
        this.isAdvanced = !this.compact;

        let sharepointURLSetting = this.$eod.getOrganizationSetting('share.sharepoint.projects.url');
            if (sharepointURLSetting && sharepointURLSetting.value) {
                this.sharepointURLTemplate = sharepointURLSetting.value;
            }
    },
    methods:{
        async saveCompany(){
            this.isCreateCompanyDialogLoading = true;
            if(!isNaN(this.createCompany.defaultService.id)){
                const company = new Company(this.createCompany);
                delete company.defaultService;
                const companySaveData = company.getSaveData();
                const newCompanyId = await this.$eod.save('Company', companySaveData).then(response => {
                    if(response && response.data && response.data.data && response.data.data.createCompany){
                        return response.data.data.createCompany.id;
                    }
                    return null;
                });

                if(newCompanyId){
                    const service = new Service(this.createCompany.defaultService);
                    delete service.id;
                    service.companyId = newCompanyId;
                    const serviceSaveData = service.getSaveData();
                    const newServiceId = await this.$eod.save('Service', serviceSaveData).then(response => {
                        if(response && response.data && response.data.data && response.data.data.createService){
                            return response.data.data.createService.id;
                        }
                        return null;
                    });

                    if(newServiceId){
                        await this.$eod.save('Company', {
                            id: newCompanyId,
                            defaultServiceId: newServiceId
                        });
                    }

                    this.updateProperty('companyId', newCompanyId);
                    this.updateProperty('company', {id: newCompanyId});

                    this.isCreateCompanyDialogVisible = false;
                }
            }

            this.isCreateCompanyDialogLoading = false;
        },
        editLocations(){
            this.locations = _.clone(this.value.locations);
            this.isLocationsDialogVisible = true;
        },
        updateProperty(propertyName, value){
            const val = this.value;
            val[propertyName] = value;
            this.$emit('input', val);
        },
        
    }
}
</script>
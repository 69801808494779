<template>
    <div class="v-search d-flex v-text-field--single-line v-text-field--rounded"
        :class="{ 'v-text-field--solo': !flat, 'v-text-field': !flat, 'eod-search__topbar': topbar }">
        <div class="v-input__control">
            <div class="v-input__slot" :style="flat ? 'margin-bottom:0;' : ''">
                <v-icon small>mdi-filter-variant</v-icon>
                <div class="d-flex flex-grow-1 v-search__filter-wrapper">
                    <div class="v-search__filter-items d-flex flex-wrap flex-grow-1">
                        <div class="v-search__filter-item align-items-center" v-for="(filter, index) in value"
                            :key="index">
                            <input v-if="filter.result" type="hidden"
                                :name="'filter[' + filter.config.filter + ']' + (filter.config.type == 'property' ? '' : '[]')"
                                :value="filter.result.id + '|' + filter.result.search_title">
                            <span class="v-search__filter-item__label">{{ filter.config.label }}</span>
                            <span class="v-search__filter-item__result" :class="filter.config.readonly?'v-search__filter-item__result-readonly':''" v-if="filter.result">{{
                                filter.result.search_title
                            }}</span>
                            <span class="v-search__filter-item__delete" v-if="filter.result && !filter.config.readonly"
                                @click="removeFilter(index)">x</span>
                        </div>
                        <div class="v-search__filter-input" v-if="!readonly">
                            <v-menu v-model="showMenu" transition="scale-transition" offset-y max-width="800"
                                :close-on-content-click="false">
                                <template v-slot:activator="{ attrs }">
                                    <input name="q" ref="input_search" v-bind="attrs" @focus="focusIn" @blur="focusOut"
                                        v-model="search" placeholder="Typ om te filteren..." type="search" autocomplete="off" @keydown="searching($event)">
                                </template>
                                <v-card>
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <eod-datetime-picker v-model="dateTimeFrom" show-dynamic-range
                                                    :dynamic-ranges="[{ value: -1, type: 'days', label: '1 dag geleden' }, { value: -1, type: 'weeks', label: '1 week geleden' }, { value: -1, type: 'months', label: '1 maand geleden' }, { value: -2, type: 'months', label: '2 maanden geleden' }]"
                                                    label="Van"></eod-datetime-picker>
                                            </v-col>
                                            <v-col>
                                                <eod-datetime-picker v-model="dateTimeUntil" show-dynamic-range
                                                    :dynamic-ranges="[{ value: 1, type: 'days', label: 'Over 1 dag' }, { value: 1, type: 'weeks', label: 'Over 1 week' }, { value: 1, type: 'months', label: 'Over 1 maand' }, { value: 2, type: 'months', label: 'Over 2 maanden' }]"
                                                    label="Tot"></eod-datetime-picker>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" block depressed @click="setDate">Instellen</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                            <div class="v-search__dropdown" v-if="show_types">
                                <div class="v-search__dropdown-item" :class="type.selected ? 'active' : ''"
                                    v-for="(type, index) in types" :key="index"
                                    @mousedown="addTypeToFilter($event, type)">
                                    <v-icon>{{ type.icon }}</v-icon>{{ type.label }}
                                </div>
                            </div>
                            <div class="v-search__dropdown" v-if="items.length > 0">
                                <div class="v-search__dropdown-item" :class="item.selected ? 'active' : ''"
                                    v-for="item in items" :key="item.id" @mousedown="addItemToFilter($event, item)">
                                    {{ item.search_title }}
                                </div>
                                <div v-if="module_items_totalcount > 0" class="v-search__dropdown-footer">
                                    {{ items.length }} van {{ module_items_totalcount }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <v-tooltip bottom v-if="!readonly">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on" @click="resetFilter">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>Wissen</span>
                </v-tooltip>
                <v-dialog v-if="value.length > 0 && value[value.length - 1].result != null && objectType && !readonly"
                    v-model="saveDialog" width="500" max-width="100%">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon small v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="ml-2">
                                    <v-icon small>mdi-content-save</v-icon>
                                </v-btn>
                            </template>
                            <span>Filter opslaan</span>
                        </v-tooltip>
                    </template>
                    <v-card>
                        <v-card-title class="mb-4">
                            <v-avatar color="primary" size="40" class="elevation-3 mr-4">
                                <v-icon dark small>
                                    mdi-content-save
                                </v-icon>
                            </v-avatar><span class="headline">Opslaan</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="newFilterList" dense outlined label="Naam" required></v-text-field>
                        </v-card-text>
                        <v-card-actions class="grey lighten-3 py-3 px-3">
                            <v-spacer></v-spacer>
                            <v-btn text @click="saveDialog = false" rounded>Annuleren</v-btn>
                            <v-btn color="secondary" depressed rounded @click="saveFilter">
                                <v-icon left>mdi-content-save</v-icon> Opslaan
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
        <div v-if="filterTemplates.length > 0 && !hideSaved && !readonly" class="v-search__templates_wrapper">
            <v-select placeholder="Opgeslagen filter" v-model="selectedFilterTemplate" hide-details class="ml-2"
                :items="filterTemplates" item-value="content" item-text="name" @change="loadFilterTemplate" dense
                outlined flat></v-select>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.v-menu__content {
    overflow: visible;
    contain: none;
}

.v-search {
    align-items: center;

    &.v-text-field--solo {
        .v-input__control {
            min-height: 38px;
        }
    }

    &.eod-search__topbar {
        border: 1px solid #dddddd;
        width: 100%;
        max-width: 100%;
        height: 35px;
        margin: 0;
    }

    &.v-text-field {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 15px;

        .v-input__slot {
            margin-bottom: 0;
            background: #ffffff;
        }

        input {
            padding: 4px 0 8px;
            font-size: .8rem;
        }
    }

    .v-search__templates_wrapper {
        max-width: 200px;
    }

    .v-search__filter-wrapper {
        //border-bottom: 1px solid var(--v-primary-base);
        padding: 5px 0 0 5px;
        text-align: left;

        /*.v-search__filter-items {
            height: 30px;
            overflow-y: scroll;
            overflow-x: hidden;
        }*/
    }

    .input-group {
        border-radius: 0.85rem;
    }

    input {

        &:active,
        &:focus {
            outline: none;
        }
    }

    .v-search__filter-input {
        position: relative;
        flex-grow: 1;
        display:flex;
        margin-bottom:5px;
        margin-right:4px;

        input {
            width: 100%;
        }
    }

    .v-search__filter-item {
        display: flex;
        margin-right: 4px;
        line-height: 1;
        margin-bottom: 5px;

        .v-search__filter-item__label {
            display: block;
            padding: .3rem .4rem .3rem .8rem;
            border-radius: 0.60rem 0 0 0.60rem;
            background-color: #dddddd;
            font-weight: normal;
            font-size: .8rem;
            color: #444444;
            height: 25px;
            line-height: 15px;
        }

        .v-search__filter-item__result {
            display: block;
            padding: .3rem .4rem;
            background-color: var(--v-primary-base);
            color: #ffffff;
            font-size: .8rem;
            height: 25px;
            line-height: 15px;
            font-weight: bold;

            &.v-search__filter-item__result-readonly{
                border-radius: 0 0.60rem 0.60rem 0;
            }
        }

        .v-search__filter-item__delete {
            display: block;
            padding: .3rem .4rem;
            border-radius: 0 0.60rem 0.60rem 0;
            background-color: var(--v-error-base);
            font-weight: normal;
            color: #ffffff;
            height: 25px;
            line-height: 14px;
            font-size: .8rem;

            &:hover {
                cursor: pointer;
                background-color: var(--v-error-lighten1);
            }
        }
    }

    .v-search__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.1875rem;
        min-width: 200px;
        max-width: 100%;
        overflow: hidden;
        padding: .45rem 0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        margin-top: 4px;
        z-index: 100;
        max-height: 210px;
        overflow-y: auto;

        .v-search__dropdown-footer {
            padding: 5px 5px 0 5px;
            text-align: center;
            color: #444444;
            border-top: thin solid rgba(0, 0, 0, 0.12);
            font-size: .7rem;
        }

        .v-search__dropdown-item {
            padding: .25rem .85rem;
            font-size: .8rem;

            .v-icon {
                font-size: 1.4rem;
                margin-right: 10px;
            }

            &.active,
            &:hover {
                background: #f5f5f5;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}
</style>
<script>
import eodDateRangePicker from './eod-date-range-picker.vue';
import eodDateTimePicker from './eod-datetime-picker.vue';

export default {
    name: 'v-search',
    components: {
        'eod-date-range-picker': eodDateRangePicker,
        'eod-datetime-picker': eodDateTimePicker,
    },
    props: {
        config: Object | Array,
        value: Array,
        prependFilterItems: Array,
        objectType: String,
        defaultFilter: String,
        templateType: {
            type: String,
            default: 'FILTER'
        },
        flat: {
            type: Boolean,
            default: false
        },
        topbar: {
            type: Boolean,
            default: false
        },
        hideSaved: {
            type: Boolean,
            default: false
        },
        readonly:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show_types: false,
            search: '',
            search_timeout: null,
            module_items: [],
            module_items_totalcount: 0,
            recompute: 0,
            selectindex: 0,
            showMenu: false,
            dates: [],
            saveDialog: false,
            newFilterList: '',
            filterTemplates: [],
            selectedFilterTemplate: null,
            settings: require('./../classes/settings'),
            dateTimeFrom: null,
            dateTimeUntil: null,
        }
    },
    mounted() {
        if (this.objectType) {
            this.loadFilterTemplates();
        }
    },
    computed: {
        show_items() {
            return this.items.length > 0;
        },
        types() {
            this.recompute;
            var types = [];
            for (let i = 0; i < this.config.length; i++) {
                if (this.config[i].label.toLowerCase().includes(this.search.toLowerCase())) {
                    var config = this.config[i];
                    config.selected = false;
                    types.push(config);
                }
            }

            if (this.selectindex < 0) {
                this.selectindex = 0;
            } else if (this.selectindex > types.length - 1) {
                this.selectindex = types.length - 1;
            }

            if (this.search != '') {
                if (types[this.selectindex]) {
                    types[this.selectindex].selected = true;
                }

            }

            if (types.length < 1) {
                this.show_types = false;
            }

            return types;
        },
        items() {
            this.recompute;
            var items = [];

            var active_filter = this.value[this.value.length - 1];

            if (active_filter && this.module_items.length > 0) {
                for (let i = 0; i < this.module_items.length; i++) {
                    var item = this.module_items[i];
                    item.selected = false;

                    if (active_filter.config.type == 'options') {
                        if (item.search_title.toLowerCase().includes(this.search.toLowerCase())) {
                            items.push(item);
                        }
                    } else {
                        items.push(item);
                    }
                }

                if (this.selectindex < 0) {
                    this.selectindex = 0;
                } else if (this.selectindex > items.length - 1) {
                    this.selectindex = items.length - 1;
                }

                if (this.search != '') {
                    if (items[this.selectindex]) {
                        items[this.selectindex].selected = true;
                    }
                }

            }

            return items;
        }
    },
    methods: {
        loadFilterTemplate(content) {
            this.$emit('input', content.filter);
            this.$emit('update');
        },
        loadFilterTemplates() {
            this.$eod.get('templates', ['id', 'name', 'content'], {
                where: [
                    {
                        column: 'type',
                        operator: '=',
                        value: this.templateType
                    },
                    {
                        column: 'object',
                        operator: '=',
                        value: this.objectType
                    }
                ]
            }).then(response => {
                if (response.data.data.templates) {
                    this.filterTemplates = response.data.data.templates.edges;
                    this.prependFilters();
                    this.$emit('templatesLoaded', this.filterTemplates);
                }
            });
        },
        prependFilters() {
            if (this.prependFilterItems) {
                for (let i = this.prependFilterItems.length - 1; i >= 0; i--) {
                    const filter = this.prependFilterItems[i];
                    this.filterTemplates.unshift(filter);
                }
            }

            if (this.defaultFilter) {
                for (let i = 0; i < this.filterTemplates.length; i++) {
                    const template = this.filterTemplates[i];
                    if (template.value == this.defaultFilter) {
                        this.selectedFilterTemplate = template.content;
                        this.$emit('input', template.content.filter);
                        this.$emit('update');
                        break;
                    }
                }
            }
        },
        setDate() {

            let active_filter = this.value[this.value.length - 1];
            if (active_filter) {
                if (active_filter.config.type == 'date-between') {
                    this.showMenu = false;

                    let label = '';
                    if (this.dateTimeFrom.type == 'fixed') {
                        label += this.$moment(this.dateTimeFrom.value).format(active_filter.config.format ? active_filter.config.format : 'YYYY-MM-DD HH:mm');
                    } else {
                        label += this.dateTimeFrom.label;
                    }

                    label += ' - ';

                    if (this.dateTimeUntil.type == 'fixed') {
                        label += this.$moment(this.dateTimeUntil.value).format(active_filter.config.format ? active_filter.config.format : 'YYYY-MM-DD HH:mm');
                    } else {
                        label += this.dateTimeUntil.label;
                    }

                    this.addItemToFilter(null, {
                        id: [this.dateTimeFrom, this.dateTimeUntil],
                        search_title: label
                    });

                    this.dateTimeFrom = null;
                    this.dateTimeUntil = null;

                } else if (active_filter.config.type == 'date') {
                    if (this.dates.length == 2) {
                        this.showMenu = false;

                        this.addItemToFilter(null, {
                            id: this.dates,
                            search_title: this.dates[0] + ' - ' + this.dates[1]
                        });

                        this.dates = [];
                    }
                }
            }
        },
        saveFilter() {
            let template = {
                name: this.newFilterList,
                object: this.objectType,
                type: this.templateType,
                content: { filter: this.value }
            };

            this.$eod.save('Template', template).then((response) => {
                this.$toaster.notify({
                    type: 'success',
                    title: 'Succes',
                    body: 'Filter is succesvol opgeslaan!'
                });

                this.loadFilterTemplates();
            }).finally(() => {
                this.saveDialog = false;
                this.newFilterList = '';
            });
        },
        resetFilter() {

            const clearedFilters = [];
            if(this.selectedFilterTemplate){
                this.selectedFilterTemplate = null;
            } else {
                const filters = this.value;
                for (let i = 0; i < filters.length; i++) {
                    const filter = filters[i];
                    if(filter.config.readonly){
                        clearedFilters.push(filter);
                    }
                }
            }

            this.$emit('input', clearedFilters);
            this.$emit('update');
            
        },
        focusIn: function () {
            if (this.value.length > 0 && (this.value[this.value.length - 1].config.type == 'date' || this.value[this.value.length - 1].config.type == 'date-between') && this.value[this.value.length - 1].result == null) {
                this.showMenu = true;
            } else if (this.value.length > 0 && this.value[this.value.length - 1].result == null) {
                this.searching();
            } else {
                this.show_types = true;
            }
        },
        focusOut: function () {
            this.hideFilter();
            this.hideItems();
            this.search = '';
        },
        hideFilter() {
            this.show_types = false;
        },
        hideItems() {
            this.module_items = [];
        },
        searchFilter() {
            this.show_types = true;
            this.recompute++;
        },
        searching(event) {

            var active_filter = this.value[this.value.length - 1];

            if (typeof event != 'undefined') {
                if (this.search == '' && event.key == 'Backspace') {
                    if (active_filter) {
                        if (active_filter.result) {
                            this.value[this.value.length - 1].result = null;
                        } else {
                            this.value.splice(this.value.length - 1, 1);
                            if (this.showMenu) {
                                this.showMenu = false;
                            }
                        }
                    }

                    if (!this.value[this.value.length - 1]) {
                        this.hideItems();
                        this.show_types = true;
                    }
                    return false;
                }

                if (event.key == 'Enter') {

                    if (this.search == '') {
                        this.$emit('update');
                        return false;
                    }

                    if (this.show_types) {
                        if (this.types[this.selectindex]) {
                            this.addTypeToFilter(event, this.types[this.selectindex]);
                            return false;
                        }
                    } else if (this.module_items.length > 0) {
                        if (this.items[this.selectindex]) {
                            this.addItemToFilter(event, this.items[this.selectindex]);
                            return false;
                        }
                    }

                    if (active_filter) {
                        if ((active_filter.config.type == 'property' || active_filter.config.type == 'search') && !active_filter.result) {
                            this.addItemToFilter(event, {
                                id: this.search,
                                search_title: this.search
                            });
                            return false;
                        }
                    } else {
                        var filter = this.config[0];
                        this.value.push({
                            config: filter,
                            result: null
                        });
                        this.addItemToFilter(event, {
                            id: this.search,
                            search_title: this.search
                        });
                    }


                }

                if (event.key == 'ArrowDown') {
                    event.preventDefault();
                    if (this.show_types || this.module_items.length > 0) {
                        this.selectindex += 1;
                        this.recompute++;
                        return false;
                    } else if (active_filter) {
                        if (!active_filter.result) {
                            this.searching();
                        } else {
                            this.show_types = true;
                        }
                    }

                    this.$forceUpdate();

                    return false;
                }

                if (event.key == 'ArrowUp') {
                    event.preventDefault();
                    if (this.show_types || this.module_items.length > 0) {
                        this.selectindex -= 1;
                        this.recompute++;
                        return false;
                    }
                }
            }

            this.hideItems();
            if (this.search_timeout) {
                clearTimeout(this.search_timeout);
            }

            if (!active_filter) {
                this.searchFilter();
                return false;
            }

            this.search_timeout = setTimeout(() => {
                this.module_items = [];

                if (!active_filter.result) {
                    var filter = active_filter.config;
                    if (filter.type == 'module') {

                        let variables = {
                            orderBy: {
                                column: "name",
                                type: "asc"
                            },
                            offset: 0,
                            limit: 10,
                            where: []
                        }

                        if(filter.settings.module == 'searchUsers'){
                            variables.orderBy = {
                                column: "firstName",
                                type: "asc"
                            };
                        }

                        if (this.search != '') {
                            if(filter.settings.module == 'searchUsers'){
                                variables.search = this.search;
                            }else{
                                for (let i = 0; i < filter.settings.fields.length; i++) {
                                    variables.where.push({
                                        column: filter.settings.fields[i],
                                        operator: "ILIKE",
                                        value: "%" + this.search + "%",
                                        chain: "or"
                                    });
                                }
                            }
                        }

                        if (filter.settings.options) {
                            variables = {
                                ...variables,
                                ...filter.settings.options
                            };
                        }

                        let fields = filter.settings.fields.slice(0);
                        fields.push('id');

                        this.$eod.get(filter.settings.module, fields, variables)
                            .then(response => {
                                if (response.status == 200) {
                                    this.module_items_totalcount = response.data.data[filter.settings.module].totalCount;
                                    var items = response.data.data[filter.settings.module].edges;
                                    for (let i = 0; i < items.length; i++) {
                                        const item = items[i];

                                        var title = filter.settings.title;
                                        for (let j = 0; j < filter.settings.fields.length; j++) {
                                            const field = filter.settings.fields[j];
                                            title = title.split('{' + field + '}').join(item[field]);
                                        }

                                        item.selected = false;
                                        item.search_title = title;
                                        this.module_items.push(item);
                                    }
                                    this.selectindex = 0;
                                }
                            });
                    } else if (filter.type == 'relation') {

                        let variables = {
                            offset: 0,
                            limit: 10,
                            where: []
                        }

                        if (this.search && this.search != '') {
                            if(filter.settings.module == 'searchUsers'){
                                variables.search = this.search;
                            }else{
                                variables.orderBy = {
                                    column: "name",
                                    type: "asc"
                                }
                                for (let i = 0; i < filter.settings.fields.length; i++) {
                                    variables.where.push({
                                        column: filter.settings.fields[i],
                                        operator: "ILIKE",
                                        value: "%" + this.search + "%",
                                        chain: "or"
                                    });
                                }
                            }
                        }

                        if (filter.settings.options) {
                            variables = {
                                ...variables,
                                ...filter.settings.options
                            };
                        }

                        let fields = filter.settings.fields.slice(0);
                        fields.push('id');

                        this.$eod.get(filter.settings.module, fields, variables)
                            .then(response => {
                                this.module_items = [];
                                this.module_items_totalcount = 0;

                                if (response.status == 200) {
                                    this.module_items_totalcount = response.data.data[filter.settings.module].totalCount;
                                    var items = response.data.data[filter.settings.module].edges;
                                    for (let i = 0; i < items.length; i++) {
                                        const item = items[i];

                                        var title = filter.settings.title;
                                        for (let j = 0; j < filter.settings.fields.length; j++) {
                                            const field = filter.settings.fields[j];
                                            title = title.split('{' + field + '}').join(item[field] ? item[field] : '');
                                        }

                                        item.selected = false;
                                        item.search_title = title;
                                        this.module_items.push(item);

                                        if (this.module_items.length > 9) {
                                            break;
                                        }
                                    }
                                    this.selectindex = 0;
                                }
                            });
                    } else if (filter.type == 'options') {

                        let options = filter.settings.options;

                        if (typeof options == 'function') {
                            options = options(this.settings);
                        }

                        for (let i = 0; i < options.length; i++) {
                            const option = options[i];
                            this.module_items.push({
                                search_title: option.text,
                                id: option.value
                            });
                        }
                    } else if (filter.type == 'active') {
                        this.module_items.push({
                            search_title: 'Actief en niet-actief',
                            id: false
                        });
                        this.module_items.push({
                            search_title: 'Enkel actief',
                            id: true
                        });
                    } else if (filter.type == 'roles') {
                        let roles = filter.settings.roles.contacts;
                        if (this.$route.meta.roles) {
                            roles = filter.settings.roles[this.$route.meta.roles];
                        }

                        for (let i = 0; i < roles.length; i++) {
                            const role = roles[i];
                            this.module_items.push({
                                search_title: this.$t(role),
                                id: role
                            });
                        }
                    } else if (filter.type == 'list') {

                        if (!this.$eod.getOrganizationSetting(filter.settings.list)) {
                            return false;
                        }

                        let listId = this.$eod.getOrganizationSetting(filter.settings.list).value;

                        let variables = {
                            orderBy: {
                                column: "name",
                                type: "asc"
                            },
                            offset: 0,
                            limit: 10,
                            where: [
                                {
                                    column: 'listId',
                                    operator: '=',
                                    value: listId,
                                    chain: 'and'
                                }
                            ]
                        }

                        if (this.search != '') {
                            for (let i = 0; i < filter.settings.fields.length; i++) {
                                variables.where.push({
                                    column: filter.settings.fields[i],
                                    operator: "ILIKE",
                                    value: "%" + this.search + "%",
                                    chain: "and"
                                });
                            }
                        }

                        let fields = filter.settings.fields.slice(0);
                        fields.push('id');

                        this.$eod.get('listItems', fields, variables)
                            .then(response => {
                                if (response.status == 200) {
                                    this.module_items_totalcount = response.data.data.listItems.totalCount;
                                    var items = response.data.data.listItems.edges;
                                    for (let i = 0; i < items.length; i++) {
                                        const item = items[i];

                                        var title = filter.settings.title;
                                        for (let j = 0; j < filter.settings.fields.length; j++) {
                                            const field = filter.settings.fields[j];
                                            title = title.split('{' + field + '}').join(item[field]);
                                        }

                                        item.selected = false;
                                        item.search_title = title;
                                        this.module_items.push(item);
                                    }
                                    this.selectindex = 0;
                                }
                            });
                    }
                } else {
                    this.searchFilter();
                }
            }, 200);

        },
        removeFilter: function (index) {
            this.value.splice(index, 1);
            this.selectedFilterTemplate = null;
            this.$emit('update');
        },
        addItemToFilter: function (event, item) {
            if (event) {
                event.preventDefault();
            }

            this.search = '';
            this.selectedFilterTemplate = null;
            this.value[this.value.length - 1].result = item;
            this.hideItems();
            this.selectindex = 0;

            this.$refs.input_search.focus();

            //this.show_types = true;
            this.$emit('update');
        },
        addTypeToFilter: function (event, filter) {
            event.preventDefault();

            this.search = '';
            this.selectedFilterTemplate = null;
            this.selectindex = 0;

            this.value.push({
                config: filter,
                result: null
            });

            if (filter.type == 'date' || filter.type == 'date-between') {
                this.showMenu = true;

                this.dateTimeFrom = {
                    value: this.$moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                    type: 'fixed',
                    label: 'Vaste datum'
                };

                this.dateTimeUntil = {
                    value: this.$moment().add('days', 1).startOf('day').format('YYYY-MM-DD HH:mm'),
                    type: 'fixed',
                    label: 'Vaste datum'
                };
            }

            this.hideFilter();
            this.searching();
        }
    }
}
</script>
<template>
<div>
    <div v-for="endpoint in endpoints" :key="endpoint.type" class="mb-4">
        <div class="d-flex align-center">
            <v-label class="mr-4">{{endpoint.title}}</v-label>
            <v-btn v-if="!readonly" small rounded depressed color="primary" @click="addItem(endpoint)"><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>
        </div>
        <v-data-table :key="updateTable[endpoint.type]" dense hide-default-footer :items-per-page="-1" v-if="value[endpoint.type] && value[endpoint.type][0]" :items="value[endpoint.type]" :headers="tableHeaders">
            <template v-slot:item.type="{ item, index }">
                <eod-object-autocomplete :disabled="readonly" return-object @input="val => updateType(endpoint, index, val)" :value="item[endpoint.typeObject]" :endpoint="endpoint.typeName"></eod-object-autocomplete>
            </template>
            <template v-slot:item.depot="{ item, index }">
                <span v-if="item.depot">{{item.depot.name}}</span>
                <span v-else>/</span>
            </template>
            <template v-slot:item.id="{ item, index }">
                <eod-objects-treeview-input :disabled="readonly" :hide-details="false" :rules="itemRequired?[val => $validator.required(val, 'Specifiëren ' + endpoint.title.toLowerCase())]:[]" return-object :selectable="[endpoint.className]" :search-config="endpoint.class.searchConfig" @input="val => updateItem(endpoint, index, val)" :search="getItemFilters(endpoint, item)" :value="item" :classes="endpoint.classes"></eod-objects-treeview-input>
            </template>
            <template v-slot:item.quantity="{ item, index }">
                <eod-text-field :disabled="readonly" v-model="item.quantity" type="number"></eod-text-field>
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-btn v-if="!readonly" @click="showDeleteItemDialog(index, endpoint)" color="error" icon><v-icon>mdi-trash-can-outline</v-icon></v-btn>
            </template>
        </v-data-table>
    </div>
    <eod-delete-dialog v-if="deleteDialogItem" v-model="isDeleteDialogVisible" :item="deleteDialogItem" @delete="deleteItem"></eod-delete-dialog>
</div>
</template>
<script>
import eodObjectAutocomplete from './eod-object-autocomplete.vue';
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue'
import Article from './../models/article'
import Tool from './../models/tool'
import eodDeleteDialog from './eod-delete-dialog.vue';

export default {
    name: 'eod-producttypes-products-selector',
    components:{
        eodObjectAutocomplete,
        eodObjectsTreeviewInput,
        eodDeleteDialog
    },
    props:{
        value: Object,
        itemRequired: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        isDeleteDialogVisible: false,
        deleteDialogItem: null,
        deleteDialogItemIndex: null,
        deleteDialogItemEndpoint: null,
        updateTable:{
            tools: 34093,
            articles: 30943
        },
        endpoints: [
            // {
            //     title:'Gereedschappen',
            //     type: 'tools',
            //     classes: ['TOOL_TYPE', 'DEPOT', 'TOOL', 'TOOL_FOLDER'],
            //     class: Tool,
            //     className: 'TOOL',
            //     object: 'Tool',
            //     typeName: 'toolTypes',
            //     typeObject: 'toolType',
            // },
            {
                title:'Artikelen',
                type: 'articles',
                classes: ['ARTICLE_TYPE', 'DEPOT', 'ARTICLE', 'ARTICLE_FOLDER'],
                class: Article,
                className: 'ARTICLE',
                object: 'Article',
                typeName: 'articleTypes',
                typeObject: 'articleType',
            }
        ],
        tableHeaders: [
            {
                value:'type',
                text:'Type*',
                sortable:false,
            },
            {
                value:'id',
                text:'Item',
                sortable:false,
            },
            {
                value:'depot',
                text:'Depot',
                sortable:false,
            },
            {
                value:'quantity',
                text:'Aantal',
                sortable:false,
            },
            {
                value:'actions',
                text:'',
                sortable:false,
                width:20
            }
        ]
    }),
    created(){
        this.convertTypesToItems();
    },
    methods:{
        deleteItem(){
            const val = this.value;
            val[this.deleteDialogItemEndpoint.type].splice(this.deleteDialogItemIndex, 1);
            this.$emit('input', val);
            this.isDeleteDialogVisible = false;
            this.updateView();
        },
        showDeleteItemDialog(itemIndex, endpoint){
            this.deleteDialogItem = {name:endpoint.title};
            this.deleteDialogItemIndex = itemIndex;
            this.deleteDialogItemEndpoint = endpoint;
            this.isDeleteDialogVisible = true;
        },
        convertTypesToItems(){
            const val = this.value;
            for (let i = 0; i < this.endpoints.length; i++) {
                const endpoint = this.endpoints[i];
                if(val[endpoint.typeName]){
                    if(!val[endpoint.type]){
                        val[endpoint.type] = [];
                    }
                    for (let j = 0; j < val[endpoint.typeName].length; j++) {
                        const type = val[endpoint.typeName][j];
                        const data = {id: null, depot: null};
                        data[endpoint.typeObject] = {...type};
                        data.quantity = type.quantity;
                        val[endpoint.type].push(data);
                    }
                    val[endpoint.typeName] = [];
                } else {
                    val[endpoint.typeName] = [];
                }
            }

            this.$emit('input', val);
        },
        addItem(endpoint){
            const val = this.value;
            if(!val[endpoint.type]){
                val[endpoint.type] = [];
            }

            const data = {
                depot: null,
                id: null,
                quantity: 1,
            }

            data[endpoint.typeObject] = null;

            val[endpoint.type].push(data);

            this.$emit('input', val);

            this.updateView();
        },
        updateView(){
            this.updateTable.tools++;
            this.updateTable.articles++;
            this.$nextTick(() => {
                this.$forceUpdate();
            })
        },
        updateType(endpoint, index, value){
            const val = this.value;
            if(val[endpoint.type][index][endpoint.typeObject] && val[endpoint.type][index][endpoint.typeObject].id != value.id){
                val[endpoint.type][index].id = null;
                val[endpoint.type][index].depot = null;
            }
            val[endpoint.type][index][endpoint.typeObject] = value;
            this.$emit('input', val);
            this.updateView();
        },
        async updateItem(endpoint, index, value){
            const val = this.value;

            val[endpoint.type][index].depot = await this.$eod.getById(endpoint.object.toLowerCase(), value.id, ['id','depot{id name}'])
            .then(response => {
                if(response.data.data && response.data.data[endpoint.object.toLowerCase()]){
                    return response.data.data[endpoint.object.toLowerCase()].depot;
                }

                return null;
            });
            val[endpoint.type][index][endpoint.typeObject] = {id:value.typeId};
            val[endpoint.type][index].id = value.id;
            this.$emit('input', val);
            this.updateView();
        },
        getItemFilters(endpoint, item){
            const filters = [];

            if(item[endpoint.typeObject]){
                const searchConfig = this.$helper.getArrayItemByProperty(Article.searchConfig, 'label', 'Type');
                filters.push({
                    config: {
                        ...searchConfig, 
                        //readonly: true
                    },
                    result: {
                        id: item[endpoint.typeObject].id,
                        name: 'Type',
                        search_title: item[endpoint.typeObject].name,
                        selected: false,
                    }
                });
            }

            return filters;
            
            
        }
    }
}
</script>
export default class Acl{
    static ACCESS = [
        {
            value: 1,
            text: 'Lezen'
        },
        {
            value: 2,
            text: 'Schrijven'
        },
        {
            value: 3,
            text: 'Lezen en schrijven'
        },
        {
            value: 4,
            text: 'Abonneren'
        }
    ];

    static getAccess(val){
        for (let i = 0; i < this.ACCESS.length; i++) {
            const acc = this.ACCESS[i];
            if(acc.value == val){
                return acc;
            }
        }

        return null;
    }
}
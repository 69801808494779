<template>
    <eod-expansion-panels v-if="value">
        <v-expansion-panel v-for="(stockMovement, stockMovementIndex) in value" :key="stockMovementIndex">
            <v-expansion-panel-header>
                <div>
                <strong>{{ stockMovement.name?stockMovement.name:'' }}</strong>
                 | {{ $moment(stockMovement.start).format('YYYY-MM-DD HH:mm') }}{{ stockMovement.end?' - ' + $moment(stockMovement.end).format('YYYY-MM-DD HH:mm'):'' }}
            </div>
            <div class="text-right px-4">
                <strong>{{ stockMovement.quantity }}</strong>
            </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col><eod-date-time-picker label="Van*" v-model="stockMovement.start"></eod-date-time-picker></v-col>
                    <v-col><eod-date-time-picker label="Tot" v-model="stockMovement.end"></eod-date-time-picker></v-col>
                </v-row>
                <v-row>
                    <v-col><eod-text-field label="Omschrijving" v-model="stockMovement.name"></eod-text-field></v-col>
                    <v-col><eod-text-field label="Aantal*" type="number" step="0.01" v-model="stockMovement.quantity"></eod-text-field></v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <eod-object-autocomplete label="Stocklocatie" endpoint="depots" v-model="stockMovement.depot" return-object></eod-object-autocomplete>
                    </v-col>
                    <v-col>
                        <eod-object-autocomplete label="Geproduceerd door" endpoint="products" v-model="stockMovement.product" return-object></eod-object-autocomplete>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </eod-expansion-panels>
</template>
<script>
import eodDateTimePicker from './eod-date-time-picker.vue'
import eodObjectAutocomplete from './eod-object-autocomplete.vue'
export default{
    name: 'eod-article-stock-movements',
    components:{
        eodDateTimePicker,
        eodObjectAutocomplete
    },
    props:{
        value: Array
    }
}
</script>
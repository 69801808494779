<template>
    <eod-dialog width="800" icon="mdi-history" title="Historiek" iconColor="primary" hide-ok cancel-text="Sluiten" v-if="requests && requests[0]" :value="value" @input="val => $emit('input', val)" max-width="600">
                        <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
                            <v-menu open-on-hover offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip class="ml-2" v-on="{ ...on, ...dialogOn }" v-bind="{ ...attrs, ...dialogAttrs }"
                                        small v-if="requests[0]" :color="requests[0].getStatusCode().color"
                                        :dark="requests[0].getStatusCode().textColor != 'dark'"><v-icon small
                                            left>mdi-history</v-icon><span><strong>{{ alterValue?alterValue(requests[0].value):requests[0].value
                                            }}</strong></span></v-chip>
                                </template>
                                <v-card>
                                    <div class="px-4 py-2"
                                        :style="'background-color:' + requests[0].getStatusCode().color + ';'"
                                        :class="requests[0].getStatusCode().textColor == 'dark' ? '' : 'white--text'">
                                        <v-icon :dark="requests[0].getStatusCode().textColor != 'dark'" small left>{{
                                            requests[0].getStatusCode().icon }}</v-icon>{{ requests[0].getStatusCode().text}}
                                    </div>
                                    <v-card-text>
                                        <div><v-icon small left>mdi-clock-outline</v-icon>{{
                                            $moment(requests[0].createdAt).format('DD/MM HH: mm')}}</div>
                                        <div v-if="requests[0].getUser()"><v-icon small left>mdi-account-outline</v-icon>{{
                                            requests[0].getUser().getFullName() }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </template>
                                <v-data-table hide-default-footer :items="requests"
                                    :headers="[{ value: 'createdAt', text: 'Tijdstip' }, { value: 'user', text: 'Gebruiker' }, { value: 'statusCode', text: 'Status' }, { value: 'value', text: 'Waarde' }]">
                                    <template v-slot:item.createdAt="{ item }">
                                        {{ $moment(item.createdAt).format('DD/MM HH:mm') }}
                                    </template>
                                    <template v-slot:item.user="{ item }">
                                        {{ item.getUser()?item.getUser().getFullName():null }}
                                    </template>
                                    <template v-slot:item.statusCode="{ item }">
                                        <v-chip small :color="item.getStatusCode().color"
                                            :dark="item.getStatusCode().textColor != 'dark'"><v-icon small left>{{
                                                item.getStatusCode().icon }}</v-icon>{{ item.getStatusCode().text}}</v-chip>
                                    </template>
                                    <template v-slot:item.value="{ item }">
                                        {{ alterValue?alterValue(item.value):item.value }}
                                    </template>
                                </v-data-table>
                    </eod-dialog>
</template>
<script>
export default {
    name: 'eod-dialog-request-history',
    props: {
        value: Boolean,
        requests: Array,
        alterValue: Function
    },
}
</script>
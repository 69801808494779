module.exports = {
    visualizations: [
        {
            graph: 'events',
            name: 'Events',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }

                if (template.content.source == 'events' && !(template.content.group && template.content.group.key != null)) {
                    return true;
                }

                return false;
            }
        },
        {
            graph: 'pie',
            name: 'Taartgrafiek',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }

                if (template.content.source == 'events' && template.content.group && template.content.group.key == 'severity') {
                    return true;
                }

                return false;
            }
        },
        {
            graph: 'table',
            name: 'Tabel',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }

                if (template.content.source == 'events' && !(template.content.group && template.content.group.key == 'time')) {
                    return false;
                }

                return true;
            }
        },
        {
            graph: 'tile',
            name: 'Waarde',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }

                if(['measurements','responses'].includes(template.content.source)){
                    return true;
                }

                return false;
            }
        },
        {
            graph: 'liquid',
            name: 'Vloeistof',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }

                if(['measurements','responses'].includes(template.content.source)){
                    return true;
                }

                return false;
            }
        }, {
            graph: 'line',
            name: 'Lijngrafiek',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }

                if(['measurements','responses'].includes(template.content.source)){
                    return true;
                }

                return false;
            }
        },
        {
            graph: 'vbar',
            name: 'Staafgrafiek verticaal',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }

                if(['measurements','responses'].includes(template.content.source)){
                    return true;
                }

                return false;
            }
        },
        {
            graph: 'profile',
            name: 'Profiel',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }
                
                if(['measurements','responses'].includes(template.content.source)){
                    return true;
                }

                return false;
            }
        },
        // {
        //     graph: 'hbar',
        //     name: 'Staafgrafiek horizontaal',
        //     isVisible: (template) => {
        //         return false;
        //     }
        // },
        {
            graph: 'radar',
            name: 'Radargrafiek',
            isVisible: (template) => {
                return false;
            }
        },
        {
            graph: 'map',
            name: 'Kaart',
            isVisible: (template) => {
                return false;
            }
        },
        {
            graph: 'sankey',
            name: 'Stroomdiagram',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return true;
                }

                return false;
            }
        },
        {
            graph: 'heatmap',
            name: 'Heatmap',
            isVisible: (template) => {
                if(template.content.type == 'recursive'){
                    return false;
                }
                
                if(['measurements','responses'].includes(template.content.source)){
                    return true;
                }

                return false;
            }
        }
    ],
    getCellTypeById(id) {
        for (let i = 0; i < this.cellTypes.length; i++) {
            const cellType = Object.assign({}, this.cellTypes[i]);
            if (cellType.id == id) {
                return cellType;
            }
        }

        return null;
    },
    cellTypes: [
        {
            id: 'graph_events',
            icon: require('./../assets/img/cells/icon-table-events.svg'),
            category: 'Grafieken',
            title: 'Events',
            settings: {
                queries: [],
                graph: {
                    type: 'events'
                }
            },
            queryFilter:{
                where:[
                    {
                        column: 'content:source',
                        operator: '=',
                        value:'events'
                    },
                    {
                        column: 'content:group.key',
                        operator: '=',
                        value:'isnull'
                    }
                ]
            },
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/query.vue'),
            w: 6,
            h: 8,
        },
        {
            id: 'graph_pie',
            icon: require('./../assets/img/cells/icon-graph-pie.svg'),
            category: 'Grafieken',
            title: 'Taartgrafiek',
            settings: {
                queries: [],
                graph: {
                    type: 'pie'
                }
            },
            queryFilter:{
                where:[
                    {
                        column: 'content:source',
                        operator: '=',
                        value:'events'
                    },
                    {
                        column: 'content:group.key',
                        operator: '=',
                        value:'severity'
                    }
                ]
            },
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/query.vue'),
            w: 6,
            h: 8,
        },
        {
            id: 'graph_table',
            icon: require('./../assets/img/cells/icon-table.svg'),
            category: 'Grafieken',
            title: 'Tabel',
            settings: {
                options:{
                    paging:{
                        itemsPerPage: 100
                    }
                },
                queries: [],
                graph: {
                    type: 'table'
                }
            },
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/table.vue'),
            w: 6,
            h: 8,
        },
        {
            id: 'graph_line',
            icon: require('./../assets/img/cells/icon-graph-line.svg'),
            category: 'Grafieken',
            title: 'Lijngrafiek',
            settings: {
                options: {
                    color: [],
                },
                querySettings: {
                    options: {
                        connectNulls: false,
                        step: null
                    },
                    settings: {
                        color: '',
                    }
                },
                queries: [],
                graph: {
                    type: 'line'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/line.vue'),
            w: 4,
            h: 8,
        },
        {
            id: 'graph_water_level',
            icon: require('./../assets/img/cells/icon-graph-liquid.svg'),
            category: 'Grafieken',
            title: 'Waterniveau',
            settings: {
                zeroPass: 0,
                queries: [],
                graph: {
                    type: 'water_level'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/water-level.vue'),
            w: 4,
            h: 8,
        },
        {
            id: 'graph_hbar',
            icon: require('./../assets/img/cells/icon-graph-hbar.svg'),
            category: 'Grafieken',
            title: 'Staafgrafiek horizontaal',
            settings: {
                options: {
                    color: [],
                },
                querySettings: {
                    settings: {
                        color: '',
                    }
                },
                queries: [],
                graph: {
                    type: 'hbar'
                }
            },
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/query.vue'),
            w: 4,
            h: 8,
        },
        {
            id: 'graph_vbar',
            icon: require('./../assets/img/cells/icon-graph-vbar.svg'),
            category: 'Grafieken',
            title: 'Staafgrafiek verticaal',
            settings: {
                options: {
                    color: [],
                },
                querySettings: {
                    settings: {
                        color: '',
                    }
                },
                queries: [],
                graph: {
                    type: 'vbar'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/query.vue'),
            w: 4,
            h: 8,
        },
        {
            id: 'graph_tile',
            icon: require('./../assets/img/cells/icon-graph-tile.svg'),
            category: 'Grafieken',
            title: 'Waarde tegel',
            settings: {
                fields:['timestamp', 'measurementType', 'product', 'ownerName'],
                queries: [],
                graph: {
                    type: 'tile'
                },
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/tile.vue'),
            w: 3,
            h: 5,
        },
        {
            id: 'graph_profile',
            icon: require('./../assets/img/cells/icon-graph-profile.svg'),
            category: 'Grafieken',
            title: 'Profiel',
            settings: {
                options: {
                    color: [],
                    legend: {
                        show: true,
                    },
                },
                querySettings: {
                    settings: {
                        color: '',
                    }
                },
                queries: [],
                graph: {
                    type: 'profile'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/query.vue'),
            w: 12,
            h: 10,
        },
        {
            id: 'graph_liquid',
            icon: require('./../assets/img/cells/icon-graph-liquid.svg'),
            category: 'Grafieken',
            title: 'Inhoud',
            info:'Meetwaarde weergeven als een ingekleurde tank of een ander svg patroon, merk op dat dit enkel op basis van een meetwaarde is die varieert tussen 0 en 1 (procentwaarde)',
            settings: {
                queries: [],
                graph: {
                    type: 'liquid'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/liquid.vue'),
            w: 3,
            h: 10,
        },
        {
            id: 'graph_sankey',
            icon: require('./../assets/img/cells/icon-graph-sankey.svg'),
            category: 'Grafieken',
            title: 'Stroomdiagram',
            settings: {
                json: '',
                queries: [],
                graph: {
                    type: 'sankey'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/sankey.vue'),
            w: 4,
            h: 8,
        },
        {
            id: 'graph_gauge',
            icon: require('./../assets/img/cells/icon-graph-gauge.svg'),
            category: 'Grafieken',
            title: 'Graadmeter',
            info:'Meetwaarde weergeven in verhouding met de minimale en maximale waarde op een graadmeter, merk op dat deze enkel kan gebruikt worden wanneer een minimum en maximum waarde werd ingesteld',
            settings: {
                queries: [],
                graph: {
                    type: 'gauge'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/gauge.vue'),
            w: 4,
            h: 8,
        },
        {
            id: 'graph_heatmap',
            icon: require('./../assets/img/cells/icon-graph-heatmap.svg'),
            category: 'Grafieken',
            title: 'Heatmap',
            info:'Grafische weergave die kleurcodering gebruikt om verschillende waarden weer te geven. Worden gebruikt voor analyses van gebruikers- en verbruiksgedrag als een sjabloon weer te geven',
            settings: {
                queries: [],
                graph: {
                    type: 'heatmap'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/heatmap.vue'),
            w: 4,
            h: 8,
        },
        /*{
            id: 'graph_radar',
            icon: require('./../assets/img/cells/icon-graph-radar.svg'),
            category: 'Grafieken',
            title: 'Radargrafiek',
            settings: {
                graph: {
                    type: 'radar'
                }
            },
            dashboard: true,
            viewComponent: () => import('./../components/dashboard/cells/graphs/query.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/query.vue'),
            w: 4,
            h: 8,
        },*/
        {
            id: 'image',
            icon: require('./../assets/img/cells/icon-image.svg'),
            category: 'Overige',
            title: 'Afbeelding',
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/other/image.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/image.vue'),
            w: 3,
            h: 3,
        },
        {
            id: 'media',
            icon: require('./../assets/img/cells/icon-image.svg'),
            category: 'Overige',
            title: 'Mediatheek',
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/other/media.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/media.vue'),
            w: 3,
            h: 3,
        },
        {
            id: 'text',
            icon: require('./../assets/img/cells/icon-text.svg'),
            category: 'Overige',
            title: 'Tekst',
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/other/text.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/text.vue'),
            w: 3,
            h: 3,
        },
        {
            id: 'graph_map',
            icon: require('./../assets/img/cells/icon-graph-map.svg'),
            category: 'Overige',
            title: 'Kaart',
            settings: {
                query: {
                    projects: true,
                    products: false
                },
                graph: {
                    type: 'map'
                }
            },
            dashboard: false,
            viewComponent: () => import('./../components/dashboard/cells/graphs/custom.vue'),
            configurationComponent: () => import('./../components/dashboard/configuration/graphs/map.vue'),
            w: 4,
            h: 8,
        }

    ],
}
<template>
    <div v-if="value.notificationSettings">
        <v-tabs vertical left>
            <v-tab class="justify-content-start" v-if="$eod.getUser().id == value.id || $eod.getUser().can('organization.contacts.update')">
                <v-icon left>
                    mdi-email-outline
                </v-icon>
                Notificaties
            </v-tab>
            <v-tab class="justify-content-start" v-if="$eod.getUser().id == value.id || $eod.getUser().can('organization.contacts.update')">
                <v-icon left>
                    mdi-bell-alert-outline
                </v-icon>
                Alarmen
            </v-tab>
            <v-tab class="justify-content-start">
                <v-icon left>
                    mdi-clipboard-text-outline
                </v-icon>
                Rapporten
            </v-tab>
            <v-tab-item :transition="false" v-if="$eod.getUser().id == value.id || $eod.getUser().can('organization.contacts.update')">
                <v-container class="pt-0">
                <v-simple-table v-if="value && value.notificationSettings">
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th v-if="value.email">E-mail</th>
                            <th>
                                <v-badge
                                    v-if="!(value.attributes && value.attributes.phone_number && value.attributes.phone_number[0])"
                                    color="error">
                                    <template v-slot:badge>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on"><v-icon small>mdi-exclamation</v-icon></span>
                                            </template>
                                            <span>Stel uw telefoonnummer in om SMS te activeren</span>
                                        </v-tooltip>
                                    </template>
                                    SMS
                                </v-badge>
                                <span v-else>SMS</span>
                            </th>
                            <th width="200">Opnieuw versturen na</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="severity in Event.SEVERITY_LIST" :key="severity.value">
                            <td>{{ severity.text }}</td>
                            <td v-if="value.email"><v-checkbox hide-details class="mt-0" value="email" @change="val => updateNotification(severity.value, 'email', val)" :input-value="value.notificationSettings.notifications[severity.value]"></v-checkbox>
                            </td>
                            <td>
                                <v-checkbox hide-details class="mt-0" value="sms"
                                    :disabled="!(value.attributes && value.attributes.phone_number && value.attributes.phone_number[0])"
                                    v-model="value.notificationSettings.notifications[severity.value]"></v-checkbox>
                            </td>
                            <td>
                                <eod-text-field :value="value.notificationSettings.notifications_resend?value.notificationSettings.notifications_resend[severity.value]:null" @input="val => updateNotificationResend(severity.value, val)">
                                    <template v-slot:append>
                                        Minuten
                                    </template>
                                </eod-text-field>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-container>
            </v-tab-item>
            <v-tab-item :transition="false" v-if="$eod.getUser().id == value.id || $eod.getUser().can('organization.contacts.update')">
                <v-container>
                <eod-objects-treeview @selectionChanged="selectionChanged" :selected="selectedAlarms" v-if="alarmTree" :children="alarmTree" :selectable="['COMPANY','PROJECT', 'TEMPLATE_ALARM']" multiple>
                    <template v-slot:item-extra="{item}">
                        <template v-if="item.module == 'templates'">
                            
                            <v-chip-group column>
                                <v-chip small v-if="item.products && item.products[0]"><v-icon small left>mdi-cube-outline</v-icon>{{ item.products[0].name }}</v-chip>
                                <v-chip small v-if="item.jobTypes && item.jobTypes[0]"><v-icon small left>mdi-form-select</v-icon>{{ item.jobTypes[0].name }}</v-chip>
                                <v-chip small v-if="item.services && item.services[0]"><v-icon small left>mdi-file-sign</v-icon>{{ item.services[0].name }}</v-chip>
                                <v-chip small v-if="item.users && item.users[0]"><v-icon small left>mdi-account-outline</v-icon>{{usersById[item.users[0].id]?usersById[item.users[0].id].getFullName():'Onbekend'}}</v-chip>
                            </v-chip-group>
                            <eod-alarm-settings style="min-width:320px;" class="mr-4 d-flex justify-content-end" :value="item"></eod-alarm-settings>
                        </template>
                    </template>
                </eod-objects-treeview>
            </v-container>
            </v-tab-item>
            <v-tab-item :transition="false">
                <v-container>
                <v-checkbox class="mt-0" label="Ik wens de rapporten te ontvangen voor alle projecten waaraan ik gekoppeld ben" v-model="value.notificationSettings.reports.sendAll" :true-value="true"  :false-value="false"></v-checkbox>
            </v-container>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import Event from '../models/event';
import User from '../models/user';
import eodObjectsTreeview from './eod-objects-treeview.vue';
import eodAlarmSettings from './eod-alarm-settings.vue';

export default {
    components:{
        eodObjectsTreeview,
        eodAlarmSettings,
    },
    props: {
        value: Object,
    },
    data() {
        return {
            Event: Event,
            alarmTree: null,
            treeItems: [],
            usersById: {},
            selectedAlarms: []
        }
    },
    created() {
        this.initSettings();
        this.loadTree().then(this.initSelected);
    },
    methods: {
        updateNotification(severity, fieldName, value){
            const val = this.value;
            if(!val.notificationSettings.notifications[severity] || !Array.isArray(val.notificationSettings.notifications[severity])){
                val.notificationSettings.notifications[severity] = [];
            }

            if(value && value.includes(fieldName)){
                val.notificationSettings.notifications[severity].push(fieldName);
            }else{
                val.notificationSettings.notifications[severity].splice(val.notificationSettings.notifications[severity].indexOf(fieldName), 1);
            }

            this.$emit('input', val);
        },
        updateNotificationResend(severity, value){
            const val = this.value;
            if(!val.notificationSettings.notifications_resend){
                val.notificationSettings.notifications_resend = {};
            }

            val.notificationSettings.notifications_resend[severity] = value;

            this.$emit('input', val);
        },
        initSettings() {
            const val = this.value;
            if (val && !val.notificationSettings) {
                val.notificationSettings = {
                    notifications: {},
                    alarms: [],
                }
            }

            if(!val.notificationSettings.notifications || typeof val.notificationSettings.notifications != 'object'){
                val.notificationSettings.notifications = {};
            }

            if(val && val.notificationSettings && !val.notificationSettings.reports){
                const defaultSetting = this.$eod.getOrganizationSetting('organization.contacts.defaultSendReports');
                val.notificationSettings.reports = {
                    sendAll: defaultSetting?defaultSetting.value == '1':false
                };
            }

            this.$emit('input', val);

            this.$nextTick(() => {
                this.$forceUpdate();
            })
        },
        selectionChanged(selection){

            const val = this.value;
            val.notificationSettings.alarms = [];

            if(selection && selection[0]){
                for (let i = 0; i < selection.length; i++) {
                    const selected = selection[i];
                    val.notificationSettings.alarms.push({
                        id: selected.id,
                        class: selected.class,
                        module: selected.module,
                    });
                }
            }

            this.selectedAlarms = selection;

            this.$emit('input', val);
        },
        initSelected(){
            let userAlarms = this.value.notificationSettings.alarms;
            if(!Array.isArray(userAlarms)){
                userAlarms = [];
            }
            const userAlarmsIds = userAlarms.map(item => {return item.id});

            if(userAlarmsIds.length > 0){
                this.selectedAlarms = this.treeItems.filter((treeItem) => userAlarmsIds.includes(treeItem.id))
            }
        },
        loadTree(){
            return this.$eod.get('templates', ['id', 'content', 'measurementTypes{id name}', 'jobTypes{id name}', 'projects{id name}', 'products{id name}', 'companies{id name}', 'services{id name}', 'users{id firstName lastName username}'], {
                where: [
                    {
                        column: 'type',
                        operator: '=',
                        value: 'ALARM_RULE'
                    }
                ]
            })
                .then(response => {
                    const templates = response.data.data.templates.edges;

                    const tree = {};
                    const userIds = [];
                    for (let i = 0; i < templates.length; i++) {
                        const template = templates[i];

                        if(!template.measurementTypes[0]){
                            continue;
                        }
                        
                        if(template.users[0]){
                            userIds.push(template.users[0].id);
                        }

                        const templateData = {
                            ...template,
                            name: template.measurementTypes[0].name,
                            class: 'TEMPLATE_ALARM',
                            module: 'templates',
                            parentIds: []
                        }

                        if(template.companies[0]){
                            if(!tree[template.companies[0].id]){
                                const company = {
                                    id: template.companies[0].id,
                                    name: template.companies[0].name,
                                    class: 'COMPANY',
                                    module: 'companies',
                                    children: {},
                                    childIds: [],
                                    parentIds: [],
                                    productsCount: 0
                                };
                                tree[company.id] = company;
                                this.treeItems.push(company);
                            }

                            templateData.parentIds.push(template.companies[0].id)
                            tree[template.companies[0].id].productsCount++;

                            if(template.projects[0]){
                                if(!tree[template.companies[0].id].children[template.projects[0].id]){
                                    const project = {
                                        id: template.projects[0].id,
                                        name: template.projects[0].name,
                                        class: 'PROJECT',
                                        module: 'projects',
                                        children: {},
                                        childIds: [],
                                        parentIds: [template.companies[0].id],
                                        productsCount: 0,
                                    };

                                    tree[template.companies[0].id].childIds.push(project.id);
                                    tree[template.companies[0].id].children[project.id] = project;
                                    this.treeItems.push(project);
                                }

                                templateData.parentIds.push(template.projects[0].id)
                                tree[template.companies[0].id].children[template.projects[0].id].productsCount++;
                                tree[template.companies[0].id].childIds.push(template.id);
                                tree[template.companies[0].id].children[template.projects[0].id].childIds.push(template.id);
                                tree[template.companies[0].id].children[template.projects[0].id].children[template.id] = templateData;
                            }else{
                                tree[template.companies[0].id].children[template.id] = templateData;
                            }
                        }else{
                            tree[template.id] = templateData;
                        }

                        this.treeItems.push(templateData);
                        
                    }

                    return {tree:tree, userIds: userIds};
                    
                }).then(async ({tree, userIds}) => {

                    this.usersById = await this.$eod.get('resolveUsers', ['id','firstName', 'lastName','username'], {
                        whereIn:[
                            {
                                column:'id',
                                array: userIds
                            }
                        ]
                    })
                    .then(response => {
                        const sorted = {};
                        if(response && response.data.data && response.data.data.resolveUsers.edges){
                            for (let i = 0; i < response.data.data.resolveUsers.edges.length; i++) {
                                const user = response.data.data.resolveUsers.edges[i];
                                sorted[user.id] = new User(user);
                            }
                        }

                        return sorted;
                    })

                    this.alarmTree = tree;
                });
        },
    }
}
</script>

const { Tool, ToolType } = require('../../eodvuecomponents');

module.exports = {
    tabs:[
        {
              path: 'tools',
              text: 'Gereedschap',
              counter: (eod) => {
                  return eod.count('countTools').then(response => {
                      if (response.data.data && response.data.data.countTools) {
                          return response.data.data.countTools.totalCount;
                      } else {
                          return 0;
                      }
                  });
              }
          },
          {
              path: 'tools.types',
              text: 'Types',
          }
        ],
    routes: [
    {
        path: '/tools',
        name: 'tools',
        meta:  {
            permissions:['products.tools.read']
        },
        component: () => import('./views/index.vue')
    },
    {
        path: '/tools/types',
        name: 'tools.types',
        meta:{
            permissions:['products.tools.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/tools/types/detail/:id?',
        name: 'toolType',
        component: () => import('./types/detail.vue')
    },
    ],
    search: Tool.searchConfig,
    index: {
        route: 'tools',
        endpoint: 'tools',
        fields: ['id', 'name', 'description', 'children{id}','toolType{id name}', 'createdAt', 'updatedAt'],
        headers: [
            {
                text: 'Naam',
                value: 'name',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: 'Type',
                value: 'toolType.name',
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
            },
            {
                text: '',
                width: 50,
                value: 'createdAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm-plus',
                    collapsed: true,
                    prepend: 'Aangemaakt op'
                }
            }, {
                text: '',
                width: 50,
                value: 'updatedAt',
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                extra: {
                    icon: 'mdi-alarm',
                    collapsed: true,
                    prepend: 'Gewijzigd op'
                }
            },
            {
                width: 120,
                sortable: false,
                component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
            }
        ],
        tabs: [],
    },
    itemObject: Tool,
    types: {
        search: [{
                'icon': 'mdi-format-text',
                'label': 'Naam',
                'type': 'property',
                'filter': 'name'
            },
        ],
        itemObject: ToolType,
        index: {
            route: 'tools.types',
            endpoint: 'toolTypes',
            fields: ['id', 'name'],
            headers: [
                {
                    text: 'Naam',
                    value: 'name',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('@/eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
        },
        detail: {
            route: 'toolType',
            endpoint: 'toolType',
            objectName: 'ToolType',
            fields: ['id', 'name', 'description'],
            tabs: []
        },
    }
}
<template>
    <div>
        <v-row v-if="ownerId">
            <template v-if="!jobTypeId">
                <v-col cols="12" md="4" lg="3">
                    <v-row>
                        <v-col class="d-flex align-items-center" cols="auto"><h2>Jobtype</h2></v-col>
                        <v-col class="d-flex align-items-center"><v-select @change="filterJobtypes" hide-details dense outlined v-model="hasTemplateFilter" :items="templateFilters"></v-select></v-col>
                    </v-row>
                    
                    <v-list>
                        <template v-for="(jobType, index) in jobTypesFiltered">
                            <v-list-item :key="jobType.id" @click="selectTemplate(jobType.id)"
                                :class="{ 'primary lighten-5': (jobTypeId == jobType.id) }">
                                <v-list-item-content>
                                    <v-list-item-title><v-badge v-if="jobType.hasTemplate" dot left inline>{{
                                        jobType.name
                                    }}</v-badge><span v-else>{{ jobType.name }}</span></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="index < jobTypesFiltered.length - 1"></v-divider>
                        </template>
                    </v-list>
                </v-col>
                <v-divider vertical></v-divider>
            </template>
            <v-col cols="12" :md="jobTypeId?12:8" :lg="jobTypeId?12:9">
                <div class="d-flex align-items-center mb-4">
                    <h2>Variabelen</h2>
                    <v-spacer></v-spacer>
                    <eod-delete-dialog v-if="value[activeTemplateIndex] && ownerId" :item="value[activeTemplateIndex]"
                        @delete="deleteConfiguration">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" depressed rounded color="error" class="mr-4"
                                :loading="sendingConfiguration"><v-icon
                                    left>mdi-trash-can-outline</v-icon>verwijderen</v-btn>
                        </template>
                    </eod-delete-dialog>
                    <v-btn v-if="value[activeTemplateIndex] && ownerId" depressed rounded color="primary"
                        :loading="sendingConfiguration" @click="sendConfiguration"><v-icon
                            left>mdi-send-outline</v-icon>Configuratie versturen</v-btn>

                            <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="align-self-center" icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="exportTemplateAction">
                                            <v-list-item-icon><v-icon>mdi-export</v-icon></v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Exporteren</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item @click="showImportDialog = true">
                                            <v-list-item-icon><v-icon>mdi-import</v-icon></v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Importeren</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                
                </div>

                <eod-dialog v-if="showImportDialog" v-model="showImportDialog" width="600" title="Importeren" icon="mdi-import" iconColor="secondary">

                    <v-file-input hide-details v-model="importFile" truncate-length="40"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                label="Bestand" outlined dense></v-file-input>

                            
                        <template v-slot:actions>
                            
                            <v-btn text rounded @click="(showImportDialog = false)">Annuleren</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :loading="importLoading" depressed :disabled="(importFile == null)" rounded
                                color="secondary" @click="importTemplateAction">
                                <v-icon left>mdi-import</v-icon>
                                Importeren
                            </v-btn>
                        </template>
                </eod-dialog>
                <table class="w-100" v-if="jobTypeTemplate && jobTypeTemplate.variables.length > 0">
                    <tr v-for="variable in jobTypeTemplate.variables" :key="variable.value">
                        <td width="300">
                            {{ variable.text }}
                        </td>
                        <td>
                            <eod-objects-treeview-input @input="val => setVariableValue(variable.value, val)" :value="getVariableValue(variable.value)?getVariableValue(variable.value).id:null" :selectable="['DEVICE']"></eod-objects-treeview-input>
                        </td>
                    </tr>
                </table>
                <v-alert type="info" text v-else-if="jobTypeTemplate">Geen variabelen gevonden voor dit
                    jobtype</v-alert>
                <v-alert type="info" text v-else>Selecteer een jobtype</v-alert>

                <v-divider class="mb-4 mt-4"></v-divider>
                <h2 class="mb-4">Meetwaardes</h2>
                <v-expansion-panels>
                    <v-expansion-panel v-for="cell in templateMeasurementTypeCells" :key="cell.id">
                        <v-expansion-panel-header v-if="resolvedMeasurementTypes[cell.settings.measurementTypeId]">
                            {{ resolvedMeasurementTypes[cell.settings.measurementTypeId].name }}<strong class="ml-2"
                                v-if="getMetricValue(cell, 'name')">({{ getMetricValue(cell, 'name') }})</strong>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <template v-if="cell.settings.product && cell.settings.product.variable">
                                <table class="w-100">
                                    <tr>
                                        <td width="300">
                                            <div class="subtitle-2">Variabele</div>
                                            {{ getVariableNameById(cell.settings.product.variable) }}
                                        </td>
                                        <td>
                                            <div class="subtitle-2">Product</div><span
                                                v-if="value[activeTemplateIndex] && resolvedProducts[value[activeTemplateIndex].content.variables[cell.settings.product.variable]]">{{
                                                    resolvedProducts[value[activeTemplateIndex].content.variables[cell.settings.product.variable]].name
                                                }}</span>
                                        </td>
                                    </tr>
                                </table>
                            </template>
                            <template v-else-if="cell.settings.product && cell.settings.product.id">
                                <table class="w-100">
                                    <tr>
                                        <td width="100"><span class="subtitle-2">Product</span></td>
                                        <td><span v-if="resolvedProducts[cell.settings.product.id]">{{
                                            resolvedProducts[cell.settings.product.id].name
                                        }}</span>
                                        </td>
                                    </tr>
                                </table>
                            </template>
                            <div class="mt-4">
                                <v-row>
                                    <v-col>
                                        <v-text-field outlined dense hide-details label="HardwareId"
                                            :value="getMetricValue(cell, 'name')"
                                            @input="val => setMetricValue(cell.id, 'name', val)"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-autocomplete outlined dense hide-details label="dataType"
                                            :items="MeasurementType.Metric_DataTypes"
                                            :value="getMetricValue(cell, 'dataType')"
                                            @input="val => setMetricValue(cell.id, 'dataType', val)"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col><v-text-field type="number" step="0.1" outlined dense hide-details label="Factor"
                                            :value="getMetricPropertyValue(cell, 'factor')"
                                            @input="val => setMetricPropertyValue(cell.id, 'factor', val)"></v-text-field></v-col>
                                    <v-col><v-text-field type="number" step="0.1" outlined dense hide-details label="Offset"
                                            :value="getMetricPropertyValue(cell, 'offset')"
                                            @input="val => setMetricPropertyValue(cell.id, 'offset', val)"></v-text-field></v-col>
                                    <v-col><v-text-field type="number" step="1" outlined dense hide-details label="Interval"
                                            :value="getMetricPropertyValue(cell, 'interval')"
                                            @input="val => setMetricPropertyValue(cell.id, 'interval', val)"></v-text-field></v-col>
                                </v-row>
                                <v-row>
                                    <v-col><v-autocomplete outlined dense hide-details label="Methode"
                                            :items="MeasurementType.Metric_Methods"
                                            :value="getMetricPropertyValue(cell, 'method')"
                                            @input="val => setMetricPropertyValue(cell.id, 'method', val)"></v-autocomplete></v-col>
                                </v-row>

                            </div>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-alert v-else type="info" text>U dient eerst de connector te bewaren voor u de configuratie kunt
            instellen!</v-alert>
    </div>
</template>
<style lang="scss" scoped>
.v-expansion-panel-header {
    transition: .2s all;

    &.v-expansion-panel-header--active {
        background-color: var(--v-primary-lighten5);
        min-height: 50px;
        padding: 10px 24px;
        margin-bottom: 10px;
    }
}
</style>
<script>
import eodObjectsTreeviewInput from './eod-objects-treeview-input.vue';
import eodDeleteDialog from './eod-delete-dialog';
import eodDialog from './eod-dialog';

export default {
    name: 'eod-job-types-config',
    components: {
        eodObjectsTreeviewInput,
        eodDeleteDialog,
        eodDialog
    },
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        ownerId: String,
        jobTypeId: String
    },
    data: () => ({
        MeasurementType: require('./../models/measurementType').default,
        activeJobTypeId: null,
        jobTypes: [],
        jobTypesFiltered: [],
        activeTemplateIndex: null,
        jobTypeTemplates: {},
        jobTypeTemplate: null,
        resolvedMeasurementTypes: {},
        resolvedProducts: {},
        templateMeasurementTypeCells: [],
        sendingConfiguration: false,
        hasTemplateFilter:true,
        templateFilters: [{
            value: null,
            text: 'Alle'
        },{
            value:true,
            text: 'Met configuratie'
        },{
            value:false,
            text: 'Zonder configuratie'
        }],
        importFile: null,
        showImportDialog: false,
        importLoading: false,
    }),
    mounted() {

        if(this.jobTypeId){
            this.$eod.getById('jobType', this.jobTypeId, ['id', 'name'])
                .then(response => {
                    this.jobTypes = [response.data.data.jobType];
                    this.selectTemplate(this.jobTypeId);
                });
            
        }else{
            this.$eod.get('jobTypes', ['id', 'name'], {
                where: [
                    {
                        'column': 'isConnectable',
                        'operator': '=',
                        'value': '1'
                    }
                ]
            }).then(response => {
                if (response.data.data.jobTypes) {
                    const jobTypes = response.data.data.jobTypes.edges;
                    for (let i = 0; i < jobTypes.length; i++) {
                        const jobType = jobTypes[i];
                        const templateIndex = this.getTemplateIndexByJobTypeId(jobType.id);
                        if (templateIndex != null) {
                            jobTypes[i].hasTemplate = true;
                        }else{
                            jobTypes[i].hasTemplate = false;
                        }
                    }
                    this.jobTypes = jobTypes;
                    this.filterJobtypes();
                }
            });
        }
    },
    methods: {
        exportTemplateAction(){
            this.$eod.exportJobTypeConfig(this.value[this.activeTemplateIndex]).then(response => {
                if (response.data.success) {
                    window.location.href = response.data.path;
                }
            });
        },
        importTemplateAction(){
            this.importLoading = true;
            this.$eod.importJobTypeConfig(this.importFile).then(response => {
                if (response.data.success) {
                    this.convertImportToTemplate(response.data.cells);
                }
            }).finally(() => {
                this.importFile = null;
                this.importLoading = false;
                
            })
        },
        convertImportToTemplate(cells){
            const templates = this.value;
            const activeTemplate = templates[this.activeTemplateIndex];

            const propertyFields = ['deviceId', 'factor','interval','method','offset'];

            for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];
                for (let j = 0; j < activeTemplate.content.metrics.length; j++) {
                    const metric = activeTemplate.content.metrics[j];
                    if(metric.properties.cellId.value == cell.cellId){
                        activeTemplate.content.metrics[j].dataType = cell.dataType || null;
                        activeTemplate.content.metrics[j].name = cell.hardwareId || null;
                        for (let fieldIndex = 0; fieldIndex < propertyFields.length; fieldIndex++) {
                            const fieldName = propertyFields[fieldIndex];
                            if(activeTemplate.content.metrics[j].properties[fieldName]){
                                activeTemplate.content.metrics[j].properties[fieldName].value = cell[fieldName] || null;
                            }
                        }
                        break;
                    }
                }
            }

            templates[this.activeTemplateIndex] = activeTemplate;

            this.$emit('input', templates);

            this.showImportDialog = false;
        },
        filterJobtypes(){
            this.jobTypesFiltered = [];

            for (let i = 0; i < this.jobTypes.length; i++) {
                const jobType = this.jobTypes[i];
                if(this.hasTemplateFilter == null){
                    this.jobTypesFiltered.push(jobType);
                }else if(jobType.hasTemplate == this.hasTemplateFilter){
                    this.jobTypesFiltered.push(jobType);
                }
            }
        },
        getActiveJobType() {
            for (let i = 0; i < this.jobTypes.length; i++) {
                const jobType = this.jobTypes[i];
                if (jobType.id == this.activeJobTypeId) {
                    return jobType;
                }
            }

            return null;
        },
        async deleteConfiguration() {
            this.sendingConfiguration = true;

            if (this.value[this.activeTemplateIndex].id) {
                try {
                    await this.$eod.get('acls', ['id', 'jobType{id name}', 'user{id name}'], {
                        where: [
                            {
                                column: 'userId',
                                operator: '=',
                                value: this.ownerId
                            },
                            {
                                column: 'jobTypeId',
                                operator: '=',
                                value: this.activeJobTypeId
                            },
                        ]
                    }).then(async response => {
                        if (response.data.data && response.data.data.acls.edges) {
                            const acls = response.data.data.acls.edges;
                            for (let i = 0; i < acls.length; i++) {
                                const acl = acls[i];
                                await this.$eod.delete('Acl', acl.id);
                            }
                        }
                    });

                    await this.$eod.delete('Template', this.value[this.activeTemplateIndex].id);
                } catch (e) {
                    console.error('Fout bij verwijderen configuratie!', e);
                }
            }

            const templates = this.value;
            templates.splice(this.activeTemplateIndex, 1);
            this.$emit('input', templates);

            this.$nextTick(() => {
                this.sendingConfiguration = false;
                this.$forceUpdate();
            });

        },
        sendConfiguration() {
            this.sendingConfiguration = true;

            const template = this.value[this.activeTemplateIndex];
            const requestData = {
                ownerId: this.ownerId,
                userId: this.$eod.getUser().id,
                metrics: template.content.metrics,
                type: 'NCMD',
                jobTypeId: this.activeJobTypeId,
                statusCode: 'to_do'
            }

            this.$eod.save('Request', requestData).then(response => {
                if (response.data.data.createRequest) {
                    this.$toaster.notify({
                        type: 'success',
                        title: this.$t('Verstuurd'),
                        body: this.$t('Configuratie is succesvol verstuurd!')
                    });
                } else {
                    this.$toaster.notify({
                        type: 'danger',
                        title: 'Fout',
                        body: 'Fout bij het versturen van de configuratie!'
                    });
                    console.error('error!', response.data);
                }
            }).catch(() => {
                this.$toaster.notify({
                    type: 'danger',
                    title: 'Fout',
                    body: 'Fout bij het versturen van de configuratie!'
                });
            }).finally(() => {
                this.sendingConfiguration = false;
            })
        },
        getMetricPropertyValue(cell, propertyName) {
            if (this.value[this.activeTemplateIndex]) {
                for (let i = 0; i < this.value[this.activeTemplateIndex].content.metrics.length; i++) {
                    const metric = this.value[this.activeTemplateIndex].content.metrics[i];
                    if (metric.properties.cellId.value == cell.id) {
                        if (metric.properties[propertyName]) {
                            return metric.properties[propertyName].value;
                        }

                    }
                }
            } else {
                // No template available, use default values of measurementType
                if (this.resolvedMeasurementTypes[cell.settings.measurementTypeId]) {
                    const measurementType = this.resolvedMeasurementTypes[cell.settings.measurementTypeId];
                    if (measurementType.metricConfig && measurementType.metricConfig[propertyName]) {
                        return measurementType.metricConfig[propertyName];
                    }
                }
            }

            return null;
        },
        setMetricPropertyValue(cellId, propertyName, val) {
            this.checkConfigTemplateExists().then(templates => {
                if (templates[this.activeTemplateIndex]) {
                    for (let i = 0; i < templates[this.activeTemplateIndex].content.metrics.length; i++) {
                        const metric = templates[this.activeTemplateIndex].content.metrics[i];
                        if (metric.properties.cellId.value == cellId) {
                            if (!templates[this.activeTemplateIndex].content.metrics[i].properties[propertyName]) {
                                templates[this.activeTemplateIndex].content.metrics[i].properties[propertyName] = {
                                    type: 'String',
                                    value: null
                                };
                            }

                            templates[this.activeTemplateIndex].content.metrics[i].properties[propertyName].value = val;
                            break;
                        }
                    }
                }

                this.$emit('input', templates);
            });
        },
        getMetricValue(cell, propertyName) {
            if (this.value[this.activeTemplateIndex]) {
                for (let i = 0; i < this.value[this.activeTemplateIndex].content.metrics.length; i++) {
                    const metric = this.value[this.activeTemplateIndex].content.metrics[i];
                    if (metric.properties.cellId.value == cell.id) {
                        return metric[propertyName];
                    }
                }
            } else {
                // No template available, use default values of measurementType
                if (propertyName == 'name') {
                    propertyName = 'hardwareId';
                }

                if (this.resolvedMeasurementTypes[cell.settings.measurementTypeId]) {
                    const measurementType = this.resolvedMeasurementTypes[cell.settings.measurementTypeId];
                    if (measurementType.metricConfig && measurementType.metricConfig[propertyName]) {
                        return measurementType.metricConfig[propertyName];
                    }
                }
            }

            return null;
        },
        setMetricValue(cellId, propertyName, val) {
            this.checkConfigTemplateExists().then(templates => {
                if (templates[this.activeTemplateIndex]) {
                    for (let i = 0; i < templates[this.activeTemplateIndex].content.metrics.length; i++) {
                        const metric = templates[this.activeTemplateIndex].content.metrics[i];
                        if (metric.properties.cellId.value == cellId) {
                            templates[this.activeTemplateIndex].content.metrics[i][propertyName] = val;
                            break;
                        }
                    }
                }

                this.$emit('input', templates);
            });
        },
        getVariableNameById(variableId) {
            if (this.jobTypeTemplate && this.jobTypeTemplate.variables) {
                for (let i = 0; i < this.jobTypeTemplate.variables.length; i++) {
                    const variable = this.jobTypeTemplate.variables[i];
                    if (variable.value == variableId) {
                        return variable.text;
                    }
                }
            }

            return null;
        },
        resolveProducts(template) {
            return new Promise(resolve => {
                const productIds = [];
                if (this.activeTemplateIndex !== null && this.value[this.activeTemplateIndex]) {
                    for (const variableId in this.value[this.activeTemplateIndex].content.variables) {
                        const productId = this.value[this.activeTemplateIndex].content.variables[variableId];
                        if (productId) {
                            productIds.push(productId);
                        }
                    }
                }

                for (let i = 0; i < template.pages.length; i++) {
                    const page = template.pages[i];
                    for (let j = 0; j < page.cells.length; j++) {
                        const cell = page.cells[j];
                        if (cell.settings && cell.settings.product && cell.settings.product.id && !productIds.includes(cell.settings.product.id) && !this.resolvedProducts[cell.settings.product.id]) {
                            productIds.push(cell.settings.product.id);
                        }
                    }
                }

                if (productIds.length > 0) {
                    this.$eod.get('resolveProducts', ['id', 'name'], {
                        whereIn: {
                            column: 'id',
                            array: productIds
                        }
                    }).then(response => {
                        if (response.data.data && response.data.data.resolveProducts && response.data.data.resolveProducts.edges) {
                            const resolveProducts = response.data.data.resolveProducts.edges;
                            for (let i = 0; i < resolveProducts.length; i++) {
                                const product = resolveProducts[i];
                                this.resolvedProducts[product.id] = product;
                            }

                            this.$forceUpdate();
                            resolve();
                        }
                    });
                }else{
                    resolve();
                }
            });
        },
        async getTemplateMeasurementTypeCells() {
            return new Promise(async resolve => {
                if (this.jobTypeTemplate) {
                    const measurementTypeIds = [];

                    const templateMeasurementTypeCells = [];

                    for (let i = 0; i < this.jobTypeTemplate.pages.length; i++) {
                        const page = this.jobTypeTemplate.pages[i];
                        for (let j = 0; j < page.cells.length; j++) {
                            const cell = page.cells[j];
                            if (cell.settings && cell.settings.measurementTypeId) {
                                templateMeasurementTypeCells.push(cell);
                                if (!this.resolvedMeasurementTypes[cell.settings.measurementTypeId]) {
                                    measurementTypeIds.push(cell.settings.measurementTypeId);
                                }
                            }
                        }
                    }

                    if (measurementTypeIds.length > 0) {
                        this.$eod.get('measurementTypes', ['id', 'name', 'metricConfig{dataType factor hardwareId interval method offset}'], {
                            whereIn: {
                                column: 'id',
                                array: measurementTypeIds
                            }
                        }).then(response => {
                            if (response.data.data && response.data.data.measurementTypes && response.data.data.measurementTypes.edges) {
                                const measurementTypes = response.data.data.measurementTypes.edges;
                                for (let i = 0; i < measurementTypes.length; i++) {
                                    const measurementType = measurementTypes[i];
                                    this.resolvedMeasurementTypes[measurementType.id] = measurementType;
                                }

                                resolve(templateMeasurementTypeCells);
                            }else{
                                resolve(templateMeasurementTypeCells);
                            }
                        }).catch(err => {
                            resolve(templateMeasurementTypeCells);
                        });
                    }else{
                        resolve(templateMeasurementTypeCells);
                    }                    
                }else{
                    resolve([]);
                }

                
            });
            
        },
        async generateMetrics(template) {
            const measurementTypeCells = await this.getTemplateMeasurementTypeCells();

            const cellIds = [];
            for (let i = 0; i < measurementTypeCells.length; i++) {
                const cell = measurementTypeCells[i];
                cellIds.push(cell.id);

                let metricIndex = null;
                for (let j = 0; j < template.content.metrics.length; j++) {
                    const metric = template.content.metrics[j];
                    if (metric.properties.cellId.value == cell.id) {
                        metricIndex = j;
                        break;
                    }
                }

                if (metricIndex == null) {
                    let metricData = {
                        name: "",
                        dataType: "Float",
                        properties: {
                            cellId: {
                                type: "String",
                                value: cell.id
                            },
                            factor: {
                                type: 'Float',
                                value: null
                            },
                            offset: {
                                type: 'Float',
                                value: null
                            },
                            interval: {
                                type: 'Float',
                                value: null
                            },
                            method: {
                                type: 'String',
                                value: null
                            },

                        }
                    };

                    if (this.resolvedMeasurementTypes[cell.settings.measurementTypeId]) {
                        const measurementType = this.resolvedMeasurementTypes[cell.settings.measurementTypeId];
                        if (measurementType.metricConfig) {
                            metricData.name = measurementType.metricConfig.hardwareId;
                            metricData.dataType = measurementType.metricConfig.dataType;
                            metricData.properties.factor.value = measurementType.metricConfig.factor;
                            metricData.properties.offset.value = measurementType.metricConfig.offset;
                            metricData.properties.interval.value = measurementType.metricConfig.interval;
                            metricData.properties.method.value = measurementType.metricConfig.method;
                        }

                    }

                    template.content.metrics.push(metricData);
                    metricIndex = template.content.metrics.length - 1;
                }

                template.content.metrics[metricIndex].properties.measurementTypeId = {
                    type: "String",
                    value: cell.settings.measurementTypeId
                }

                if (cell.settings.product && cell.settings.product.id) {
                    template.content.metrics[metricIndex].properties.deviceId = {
                        type: "String",
                        value: cell.settings.product.id
                    }
                } else if (cell.settings.product && cell.settings.product.variable && template.content.variables[cell.settings.product.variable]) {
                    template.content.metrics[metricIndex].properties.deviceId = {
                        type: "String",
                        value: template.content.variables[cell.settings.product.variable]
                    }
                }
            }

            const metrics = Object.assign([], template.content.metrics);

            for (let i = 0; i < metrics.length; i++) {
                const metric = metrics[i];
                if (!cellIds.includes(metric.properties.cellId.value)) {
                    template.content.metrics.splice(i, 1);
                }
            }

            return template;

        },
        checkConfigTemplateExists() {
            return new Promise(async (resolve) => {
                const templates = this.value;

                if (this.activeTemplateIndex == null) {
                    // Set ownerId and jobTypeId in content
                    templates.push({
                        type: 'JOB_TYPE_CONFIG',
                        jobTypes: [this.getActiveJobType()],
                        users: [{id:this.ownerId}],
                        object: 'USER',
                        content: {
                            jobTypeId: this.activeJobTypeId,
                            ownerId: this.ownerId,
                            variables: {},
                            metrics: []
                        },
                        isActive: true,
                        name: 'Configuratie ' + this.getActiveJobType().name,
                    });

                    this.activeTemplateIndex = templates.length - 1;
                    templates[this.activeTemplateIndex] = await this.generateMetrics(templates[this.activeTemplateIndex]);
                    this.$emit('input', templates);
                }

                this.$nextTick(() => {
                    resolve(templates);
                });
            })

        },
        setVariableValue(variableId, val) {
            this.checkConfigTemplateExists().then(async templates => {

                if (!templates[this.activeTemplateIndex].content.variables) {
                    templates[this.activeTemplateIndex].content.variables = {};
                }

                templates[this.activeTemplateIndex].content.variables[variableId] = val;
                templates[this.activeTemplateIndex] = await this.generateMetrics(templates[this.activeTemplateIndex]);

                this.$emit('input', templates);

                this.$nextTick(() => {
                    this.resolveProducts(this.jobTypeTemplate);
                });
            });
        },
        getVariableValue(variableId) {
            if (this.activeTemplateIndex == null) {
                return null;
            }

            const template = this.value[this.activeTemplateIndex];
            if (!template.content || !template.content.variables) {
                return null;
            }

            if (template.content.variables[variableId]) {

                const productId = template.content.variables[variableId];
                if(this.resolvedProducts[productId]){
                    return this.resolvedProducts[productId];
                }

                return {id: productId};
            }

            return null;
        },
        async selectTemplate(jobTypeId) {
            this.activeTemplateIndex = null;
            this.activeJobTypeId = jobTypeId;

            const templateIndex = this.getTemplateIndexByJobTypeId(jobTypeId);
            if (templateIndex !== null) {
                this.activeTemplateIndex = templateIndex;
            } else {
                // Add template
                await this.checkConfigTemplateExists();
            }

            this.loadTemplateVariables(jobTypeId).then(async response => {
                this.templateMeasurementTypeCells = await this.getTemplateMeasurementTypeCells();
                if (this.activeTemplateIndex != null) {
                    const templates = this.value;
                    templates[this.activeTemplateIndex] = await this.generateMetrics(templates[this.activeTemplateIndex]);
                    this.$emit('input', templates);

                    this.$nextTick(() => {
                        this.$forceUpdate();
                    })
                    
                }
            });
        },
        loadTemplateVariables(jobTypeId) {
            // Get jobtype templates
            this.jobTypeTemplate = null;

            return this.getJobTypeTemplate(jobTypeId).then(template => {
                if (!template) {
                    return null;
                }

                if (!template.content.variables) {
                    return null;
                }

                return template.content;
            }).then(async jobTypeTemplate => {

                if (jobTypeTemplate) {
                    await this.resolveProducts(jobTypeTemplate);
                    
                    this.jobTypeTemplate = jobTypeTemplate;
                }
            });
        },
        getJobTypeTemplate(jobTypeId) {
            return new Promise((resolve) => {
                // Temp get template via jobType
                if (this.jobTypeTemplates[jobTypeId]) {
                    resolve(this.jobTypeTemplates[jobTypeId]);
                }

                this.$eod.get('templates', ['id', 'name', 'content', 'type'], {
                    whereInRelated: [{
                        column: 'jobTypeId',
                        relation: 'jobTypes_templates',
                        array: [jobTypeId]
                    }],
                    where: [
                        {
                            column: 'type',
                            operator: '=',
                            value: 'FORM'
                        }
                    ]
                }).then(response => {
                    if (response.data.data) {
                        const templates = response.data.data.templates.edges;
                        if (templates[0]) {
                            this.jobTypeTemplates[jobTypeId] = templates[0];
                            resolve(templates[0]);
                        }
                    }

                    resolve(null);
                });
            });
        },
        getTemplateIndexByJobTypeId(jobTypeId) {
            for (let i = 0; i < this.value.length; i++) {
                const template = this.value[i];
                if (template.type == 'JOB_TYPE_CONFIG' && template.jobTypes) {
                    for (let j = 0; j < template.jobTypes.length; j++) {
                        const jobType = template.jobTypes[j];
                        if (jobType.id == jobTypeId) {
                            return i;
                        }
                    }
                }
            }
            return null;
        }
    }
}
</script>
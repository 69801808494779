export default class TreeObject {
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    static icons = {
        PROJECT: 'mdi-folder-open-outline',
        COMPANY: 'mdi-office-building-outline',
        ARTICLE: 'mdi-package-variant',
        TOOL: 'mdi-tools',
        DEVICE: 'mdi-cube-outline',
        DEVICE_FOLDER: 'mdi-folder-open',
        TOOL_FOLDER: 'mdi-folder-open',
        ARTICLE_FOLDER: 'mdi-folder-open',
        TEMPLATE_ALARM: 'mdi-bell-alert-outline',
        DEPOT: 'mdi-warehouse',
    };

    static getIcon(value){
        return this.icons[value] || null;
    }
}
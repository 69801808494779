<template>
    <div ref="waterLevelGraph" class="h-100 w-100 position-relative water-level">
        <eod-dialog @okAction="saveCellSettings" v-model="isSetZeroPassDialogVisible" okText="Opslaan" icon="mdi-pencil-outline" iconColor="primary" title="Nulpas aanpassen">
            <eod-text-field class="mt-4" suffix="meter (m)" step="0.01" type="number" label="Hoogteverschil bovenkant peilbuis t.o.v. nulpas" v-model="cellSettings.zeroPass"></eod-text-field>
        </eod-dialog>
        <div class="water-level__zeropass" :style="'top:'+(zeroPassHeight<0?Math.abs(zeroPassHeight):0)+'%;'">
            <eod-card class="water-level__zeropass-tile">
                <label>Nulpas</label>
                <v-btn @click="isSetZeroPassDialogVisible=true" v-if="showSetValueButton() && $eod.getUser().can('requests.create') && dashboardId" class="ml-2" x-small dark icon><v-icon small>mdi-pencil-outline</v-icon></v-btn>
            </eod-card>
        </div>
        <div class="water-level__zeropass-distance water-level__arrows" v-if="cell.settings.zeroPass && zeroPassHeight != 0" :style="'height:'+Math.abs(zeroPassHeight)+'%;'">
            <eod-card class="water-level__zeropass-distance-tile d-flex align-center" :style="'top:'+(zeroPassHeight > 10 || zeroPassHeight < -10?'50%;':'20px')+';'">
                <div>
                    <label>Afstand nulpas t.o.v. bovenkant peilbuis</label>
                    {{ parseFloat(cell.settings.zeroPass).toFixed(2) }} m
                </div>
            </eod-card>
        </div>
        <div class="water-level__ground" :style="'height:'+(zeroPassHeight>0?100-zeroPassHeight:100)+'%;'">
            <div class="water-level__zeropass">
            <eod-card class="water-level__zeropass-tile" style="right:auto;left:10px;">
                <label>Bovenkant peilbuis</label>
            </eod-card>
        </div>
        <div class="water-level__graph">
            <div class="water-level__water" :style="'height:'+(((1-(waterLevel/offset*0.9))*100))+'%;'">
                <eod-card class="water-level__water__tile" :style="'left:'+(containerWidth && containerWidth < 550?(containerWidth/2-84):140)+'px;'">
                    <label>Waterpeil</label>
                    {{ waterLevel.toFixed(2) }} m
                </eod-card>
            </div>
            <div class="water-level__wanted" :style="'height:'+wantedLevel+'%;'">
                <div class="water-level__wanted-arrow water-level__arrows"></div>
                <eod-card class="water-level__water__tile water-level__water__tile__wanted" :style="'right:'+(containerWidth && containerWidth < 550?(containerWidth/2-140):140)+'px;'">
                    <div>
                        <label>Gewenst waterpeil</label>
                        <div class="d-flex align-center">
                        {{ alterWantedLevelShowValue(wantedLevel).toFixed(2) }} m
                        <v-spacer></v-spacer>
                    <v-btn dark v-if="showSetValueButton() && $eod.getUser().can('requests.create')" @click="showDialog(wantedLevel)" small
                    icon><v-icon small>mdi-pencil</v-icon></v-btn>
                    <eod-dialog-request :alterValue="alterWantedLevelSaveValue" :defaultValue="newValue" :settings="getRequestSettings(cell.settings)" v-if="showSetValueButton() && showSetValueDialog" v-model="showSetValueDialog" @reload="update"></eod-dialog-request>
                    <template v-if="showSetValueButton() && $eod.getUser().can('requests.read')">
                        <eod-dialog-request-history :alterValue="alterWantedLevelShowValue" v-model="isRequestHistoryDialogVisible" :requests="requests"></eod-dialog-request-history>
                    </template>
                        </div>
                    </div>
                    
                </eod-card>
            </div>
            <div class="water-level__sensor">
                <div class="water-level__sensor-arrow water-level__arrows"></div>
            <v-tooltip top>
                <template v-slot:activator="{attrs, on}">
                    <eod-card :style="'left:'+(containerWidth && containerWidth < 550?(containerWidth/2-130):100)+'px;'" v-bind="attrs" v-on="on" class="water-level__water__tile water-level__water__tile__offset" color="black" dark>
                        <label>Installatiediepte</label>
                        <span v-if="offset">{{ parseFloat(offset).toFixed(2) }} m</span>
                    </eod-card>
                </template>
                Installatiediepte t.o.v. bovenkant peilbuis
            </v-tooltip>
                
            </div>
        </div>
    </div>
        
    </div>
</template>
<style lang="scss" scoped>
.water-level{
    background-color: #EEEEEE;
}
.water-level__arrows{
    &::before{
        content: "";
        position: absolute;
        left:-1.5px;
        top:0;
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 10px solid var(--v-warning-base);
        transform: translateX(-50%);
    }

    &::after{
        content: "";
        position: absolute;
        left:-1.5px;
        bottom:0;
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid var(--v-warning-base);
        transform: translateX(-50%);
    }
}
.water-level__zeropass-distance{
    position: absolute;
    top:0;
    left: 20px;
    width:calc(100% - 40px);
    height: 0;
    border-left: 3px solid var(--v-warning-base);
    z-index: 10;

    .water-level__zeropass-distance-tile{
        position: absolute;
        left:40px;
        top:50%;
        transform: translateY(-50%);
        padding: 6px 10px;
        font-weight: bold;
        line-height: 1;
        background-color: var(--v-warning-base);
        color: #ffffff;

        label{
            font-weight: normal;
        }
    }

    &:hover{
        z-index: 50;
    }
}
.water-level__zeropass{
    position: absolute;
    top:0;
    width:100%;
    height: 0;
    border-bottom: 3px dotted var(--v-error-base);
    z-index: 20;

    .water-level__zeropass-tile{
        position: absolute;
        right:10px;
        top:0;
        line-height: 1;
        padding: 6px 10px;
        background-color: var(--v-error-base);
        color: #ffffff;
        display: flex;
        align-items: center;
    }
}
.water-level__ground{
    width:100%;
    height:90%;
    position: absolute;
    left:0;
    bottom:0;
    // background-image: url('./../../assets/img/graphs/water-level-ground.jpg');
    // background-size: 200px 200px;
    // background-repeat: repeat;
}
.water-level__sensor{
    position:absolute;
    left:49%;
    width:2px;
    background: #000000;
    height: 95%;
    top:0;

    .water-level__sensor-arrow{
        position:absolute;
        top:0;
        height: 100%;
        left:60px;
        border-right: 3px solid #000000;
        &::before{
            left:auto;
            right:-1.5px;
            border-bottom: 10px solid #000000;
            transform: translateX(50%);
        }

        &::after{
            left:auto;
            right:-1.5px;
            border-top: 10px solid #000000;
            transform: translateX(50%);
        }
    }

    &::before{
        content: "";
        width:100px;
        position: absolute;
        bottom: 0px;
        border-top: 3px dotted #000000;
    }

    &::after{
        content: "";
        position: absolute;
        bottom: 0;
        height: 40px;
        left: -350%;
        background: #000000;
        width: 800%;
        border-radius: 10px;
    }
}
.water-level__graph{
    position: absolute;
    width:100px;
    left:50%;
    transform: translateX(-50%);
    height:90%;
    bottom:10%;
    border-left:4px solid #bbbbbb;
    border-bottom:4px solid #bbbbbb;
    border-right:4px solid #bbbbbb;
    background-color: #888888;

    .water-level__water__tile{
        position: absolute;
        top:5px;
        transform: translateY(-100%);
        left: 140px;
        padding: 6px 10px;
        font-weight: bold;
        line-height: 1;
        z-index:5;
        min-width: 120px;

        &:hover{
            z-index: 10;
            
        }

        label{
            display: block;
            font-size: .8em;
            font-weight: normal;
            margin-bottom: 2px;
        }

        &.water-level__water__tile__offset{
            top: auto;
            bottom:5px;
            left: 100px;
            transform: translateY(100%);
            z-index: 2;

            &:hover{
                z-index: 10;
            }
        }

        &.water-level__water__tile__wanted{
            left:auto;
            right:140px;
            background-color: var(--v-success-base);
            color: #ffffff;
            top:auto;
            bottom:5px;
            transform: translateY(100%);
            min-width: 180px;
        }
    }
    .water-level__wanted{
        position: absolute;
        top: 0;
        width:100%;

        .water-level__wanted-arrow{
            position: absolute;
            width: 0;
            height: 100%;
            left:-20px;
            border-left: 3px solid var(--v-success-base);

            &::before{
                border-bottom: 10px solid var(--v-success-base);
            }

            &::after{
                border-top: 10px solid var(--v-success-base);
            }
        }

        &::before{
            content: "";
            width:200px;
            position: absolute;
            bottom: 0;
            right:0;
            border-top: 3px dotted var(--v-success-base);
        }
    }
    .water-level__water{
        width:100%;
        background-image: url('./../../assets/img/graphs/water-level-water.jpg');
        background-size: 100px;
        background-repeat: repeat;
        position: absolute;
        bottom: 0;

        &::before{
            content: "";
            width:200px;
            position: absolute;
            top: 0;
            border-top: 3px dotted #ffffff;
        }
    }
}
</style>
<script>
import cellRequest from './../dashboard/mixins/cellRequest';
import eodDialogRequestHistory from './eod-dialog-request-history.vue';
import Request from './../../models/request';
import eodDialog from './../eod-dialog.vue';
import eodDialogRequest from './eod-dialog-request.vue';

    export default {
        name: 'eod-graph-water-level',
        components: {
            eodDialogRequestHistory,
            eodDialogRequest,
            eodDialog
        },
        props:{
            id: String,
            value:Object|Array,
            template:Object|Array,
            xAxis:Object|Array,
            cell: Object,
            dashboardId: String,
        },
        mixins:[cellRequest],
        data: () => ({
            waterLevel: 0,
            offset: 0,
            wantedLevel: 0,
            groundHeight: 0,
            isRequestHistoryDialogVisible: false,
            isSetZeroPassDialogVisible: false,
            cellSettings: {},
            requests: [],
            containerWidth: null,
            containerWidthInterval: null,
        }),
        created(){
            this.cellSettings = this.cell.settings;
            this.setContainerWidth();
        },
        beforeDestroy(){
            clearInterval(this.containerWidthInterval);
        },
        computed:{
            zeroPassHeight(){
                if(this.cell.settings.zeroPass){
                    const zeroPass = parseFloat(this.cell.settings.zeroPass);
                    const depth = Math.abs(this.offset);
                    const totalHoleDepth = depth/95*100;
                    const totalGroundDepth = totalHoleDepth/90*100;
                    const totalDepth = (zeroPass>0?zeroPass:0) + totalGroundDepth;

                    return (zeroPass/totalDepth*100).toFixed(2);
                }

                return 0;
            }
        },
        methods: {
            alterWantedLevelShowValue(value){
                return (this.offset/100*value);
            },
            alterWantedLevelSaveValue(value){
                if(this.offset != null){
                    return value/this.offset*100;
                }
                return value;
            },
            getValueQuery(){
                for (let i = 0; i < this.value.length; i++) {
                    const query = this.value[i];
                    if(query.queryIndex == 1){
                        return this.value[i];
                    }
                }
            },
            getValueTemplate(){
                if(this.template && this.template[1]){
                    return this.template[1];
                }

                return null;
            },
            saveCellSettings(){
                const cell = this.cell;
                cell.settings = this.cellSettings;
                this.$emit('updateCell', cell);
                this.isSetZeroPassDialogVisible = false;
            },
            setContainerWidth(){
                let graph = null;
                this.containerWidthInterval = setInterval(() => {
                    if(!graph){
                        if(this.$refs.waterLevelGraph){
                            graph = this.$refs.waterLevelGraph;
                        }
                    }

                    if(graph){
                        this.containerWidth = graph.offsetWidth;
                    }
                    
                }, 1000);
            },
            async update(){
                if (this.showSetValueButton()) {
                    this.requests = [];

                    const measurementType = await this.getMeasurementType();

                    await this.getRequests(5).then(response => {
                        if (response.data.data.requests && response.data.data.requests.edges[0]) {
                            const requests = response.data.data.requests.edges;
                            for (let i = 0; i < requests.length; i++) {
                                let request = requests[i];

                                if (measurementType.valueItems) {
                                    if (measurementType.valueItems[request.value]) {
                                        request.value = measurementType.valueItems[request.value].name;
                                    }
                                }

                                this.requests.push(new Request(request));
                            }
                        }
                    });
                }

                let waterLevelResult = null;
                let wantedLevelResult = null;

                for (let i = 0; i < this.value.length; i++) {
                    const query = this.value[i];
                    if(query.queryIndex == 0){
                        waterLevelResult = query.data[0];
                    } else if (query.queryIndex == 1){
                        wantedLevelResult = query.data[0];
                    }
                }

                if(!waterLevelResult){
                    return false;
                }

                const jobTypeConfigTemplate = await this.$eod.get('templates', ['id', 'content'], {
                    where:[
                        {
                            column:'type',
                            operator:'=',
                            value: 'JOB_TYPE_CONFIG'
                        }
                    ],
                    whereInRelated: [{
                        column: 'jobTypeId',
                        relation: 'jobTypes_templates',
                        array: [waterLevelResult.job_type_id]
                    },
                    {
                        column: 'userId',
                        relation: 'users_templates',
                        array: [waterLevelResult.owner_id]
                    }],
                }).then(response => {
                    if(response.data && response.data.data && response.data.data.templates){
                        return response.data.data.templates.edges[0] || null;
                    }
                });

                if(jobTypeConfigTemplate){
                    for (let i = 0; i < jobTypeConfigTemplate.content.metrics.length; i++) {
                        const metric = jobTypeConfigTemplate.content.metrics[i];
                        
                        if(metric.properties && metric.properties.measurementTypeId && metric.properties.measurementTypeId.value == waterLevelResult.measurement_type_id){
                            this.offset = metric.properties.offset?metric.properties.offset.value:null;
                        }
                    }
                }

                this.waterLevel = parseFloat(waterLevelResult.value);

                if(wantedLevelResult){
                    this.wantedLevel = parseFloat(wantedLevelResult.value);
                }

                this.$emit('finished', this.id);
            }
        }
    }
</script>
<template>
    <vc-date-picker :popover="{ visibility: 'click' }"
                                            :minute-increment="minutesIncrement"
                                            :attributes="[{ key: 'today', highlight: { color: 'orange', fillMode: 'solid', class: 'primary' }, dates: new Date() }]"
                                            :value="valueFormatted" @input="val => dateChanged(val)"
                                            :mode="mode" is24hr
                                            :model-config="{ type: 'string', mask: mask }">
                                            <template v-slot="{ inputValue, inputEvents, updateValue }">
                                                <eod-text-field hide-details :prepend-icon="icon"
                                                    v-bind="$attrs"
                                                    :value="inputValue"
                                                    @input=" updateValue($event, { formatInput: false, hidePopover: false, debounce: 1000 })"
                                                    @change=" updateValue($event, { formatInput: true, hidePopover: false, })"
                                                    v-on="inputEvents"></eod-text-field>
                                            </template>
                                        </vc-date-picker>
</template>
<script>
import moment from 'moment';

export default {
    name: 'eod-date-time-picker',
    props: {
        value:{
            type: String,
            default: moment().toISOString()
        },
        mask:{
            type: String,
            default: 'DD-MM-YYYY HH:mm'
        },
        minutesIncrement:{
            type: Number,
            default: 1
        },
        icon:{
            type: String,
            default: 'mdi-calendar'
        },
        mode:{
            type: String,
            default: 'dateTime'
        },
    },
    computed:{
        valueFormatted(){
            if(this.value){
                return this.$moment(this.value).toISOString()
            }
            return null;
        },
    },
    methods:{
        dateChanged(val){
            if(!val){
                this.$emit(input,null);
                return;
            }

            this.$emit('input', this.$moment(val, this.mask).toISOString());
        },
    }
}
</script>
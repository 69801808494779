import moment from 'moment';
import {RRule, RRuleSet, rrulestr} from 'rrule';
import _ from 'lodash';

export default class Availability {
    static ADVANCED_RECURRING_DAYS = [
        {
            value: RRule.MO,
            text: 'Ma'
        },
        {
            value: RRule.TU,
            text: 'Di'
        },
        {
            value: RRule.WE,
            text: 'Wo'
        },
        {
            value: RRule.TH,
            text: 'Do'
        },
        {
            value: RRule.FR,
            text: 'Vr'
        },
        {
            value: RRule.SA,
            text: 'Za'
        },
        {
            value: RRule.SU,
            text: 'Zo'
        },
    ]
    static ADVANCED_RECURRING_RULES = [{
            value: RRule.DAILY,
            text: 'dagen'
        },
        {
            value: RRule.WEEKLY,
            text: 'weken'
        },
        {
            value: RRule.MONTHLY,
            text: 'maanden'
        },
        {
            value: RRule.YEARLY,
            text: 'jaren'
        },
    ]

    static BASIC_RECURRING_RULES = [
        {
            value: null,
            text: 'Niet herhalen'
        },
        {
            value: {
                freq: RRule.DAILY,
                interval: 1
            },
            text: 'Dagelijks'
        },
        {
            value: {
                freq: RRule.WEEKLY,
                interval: 1
            },
            text: 'Wekelijks'
        },
        {
            value: {
                freq: RRule.MONTHLY,
                interval: 1
            },
            text: 'Maandelijks'
        },
        {
            value: {
                freq: RRule.YEARLY,
                interval: 1
            },
            text: 'Jaarlijks'
        },
        {
            value: {
                until: null,
                count: null,
                freq: RRule.WEEKLY,
                interval: 1
            },
            text: 'Anders...'
        },
    ]

    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }

    get timed() {
        return true;
    }

    get color() {
        return 'success lighten-1';
    }

    contains(date){
        const ruleSet = rrulestr(this.recurrence, {forceset:true});
        const moment_date = moment(date);
        const moment_start = moment_date.clone().startOf('day');
        const moment_end = moment_date.clone().endOf('day');
        const dates = ruleSet.between(moment_start.toDate(), moment_end.toDate());

        for (let i = 0; i < dates.length; i++) {
            const available_date = dates[i];
            const start = moment(available_date);
            const moment_end_datetime = moment(this.endDateTime);
            const end = moment(start.format('YYYY-MM-DD')+' '+moment_end_datetime.format('HH:mm'));
            if(moment_date.isBetween(start, end, 'minutes', '[]')){
                return available_date;
            }
        }

        return false;
    }
    freqByValue(val){
        for (let i = 0; i < Availability.BASIC_RECURRING_RULES.length; i++) {
            const rule = Availability.BASIC_RECURRING_RULES[i];
            if(rule.value && rule.value.freq == val){
                return rule;
            }else if (rule.value == val){
                return rule;
            }
        }
        return null;
    }
    getFirstEventAfter(moment_start){
        const ruleSet = rrulestr(this.recurrence, {forceset:true});
        return ruleSet.after(moment_start.toDate());
    }
    getRecurringEvents(moment_start, moment_end){
        if(!this.recurrence){
            return null;
        }

        const recurringEvents = [];

        const availabilityStartDate = moment(this.startDateTime);
        const availabilityEndDate = moment(this.endDateTime);

        const ruleSet = rrulestr(this.recurrence, {forceset:true});
        const dates = ruleSet.between(moment_start.toDate(), moment_end.toDate());

        const duration = availabilityEndDate.diff(availabilityStartDate, 'milliseconds');

        for (let i = 0; i < dates.length; i++) {
            const moment_start = moment(this.startDateTime).tz('Europe/Brussels');
            const date = moment(dates[i]).set({
                hour: moment_start.hours(),
                minute: moment_start.minutes(),
                second: moment_start.seconds(),
            });

            if(date.isAfter(availabilityEndDate)){
                const start = date.clone();
                const end = date.add(duration, 'milliseconds').clone();
                let event = this.getSaveData();
                event.startDateTime = start.toISOString();
                event.endDateTime = end.toISOString();
                event.isOriginal = false;

                recurringEvents.push(new Availability(event));
            }

        }
        
        return recurringEvents;
    }

    getSaveData(){
        const fields = ['startDateTime', 'endDateTime', 'endTimeUnspecified', 'recurrence'];
        let data = {};

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            data[field] = this[field];
        }

        return data;
    }
}

<template>
    <div>
    <v-data-table :items="items" :headers="headers" item-key="time" :items-per-page="10" dense>
        <template v-slot:item.time="{ item }">
            {{ $moment(item.time).tz('UTC').format('DD/MM HH:mm') }}
        </template>
        <template v-slot:item.severity="{ item }">
            <v-chip small :color="Event.getSeverity(item.severity) ? Event.getSeverity(item.severity).color : 'default'" dark>
                {{ Event.getSeverity(item.severity) ? Event.getSeverity(item.severity).text : item.severity }}
            </v-chip>
        </template>
    </v-data-table>
</div>
</template>
<script>
import Event from '../models/event';

export default {
    name: 'eod-logs',
    props: {
        filters: Object
    },
    data: () => ({
        Event: Event,
        items: [],
        headers:[{
            text: 'Tijd',
            value: 'time'
        },
        {
            text: 'Niveau',
            value: 'severity'
        },
        {
            text: 'Bericht',
            value: 'message'
        }]
    }),
    mounted(){
        if(this.filters){
            this.$eod.get('logs', ['time', 'message', 'severity'], this.filters)
                .then(response => {
                    if(response.data && response.data.data && response.data.data.logs){
                        this.items = response.data.data.logs.edges;
                        this.$forceUpdate();
                    }
                });
        } else {
            console.error('eod-logs: filters required!');
        }
    }
}
</script>
<template>
    <v-data-table :headers="config.index.headers" :items="visibleItems" :options.sync="options"
        :server-items-length="totalItems" :footer-props="{
            'items-per-page-options': [15, 50, 100],
            showFirstLastPage: true,
        }" :loading="loading" locale="nl-BE" item-key="id" :expanded.sync="expanded" dense @dropped="droppedItem"
        @toggleChildren="toggleChildren">
        <template v-slot:item="{ item, expand, isExpanded }">
            <tr :class="isExpanded ? 'grey lighten-3 elevation-3' : ''"
                :data-tree-collapsed="(item.hasOwnProperty('treeCollapsed') && !item.treeCollapsed) ? false : true"
                :data-tree-level="item.treeLevel ? item.treeLevel : 0" :data-item-id="item.id"
                :data-children-count="item.numberOfChildren">
                <component v-for="(header, index) in config.index.headers" ref="item" v-bind="componentProps"
                    :key="index" :is="header.component" :item="item" :fieldname="header.value" :config="config"
                    :readonly="item.isReadonly||false"
                    :extra="header.extra" @update="getDataFromApi" :showDuplicate="showDuplicate"
                    @duplicate="$emit('duplicate', item)" :expand="expand" :isExpanded="isExpanded"
                    @edit="editItemAction" @report="reportAction"></component>
            </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }" v-if="config.index.expanded">
            <component :is="config.index.expanded.component" :headers="headers" :item="item" :config="config"
                @updatedStatus="updatedStatus" @updateList="getDataFromApi"></component>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        config: Object,
        filters: Array,
        timeFilters: Object,
        componentProps: Object,
        showDuplicate: Boolean,
        prepareItems: Function,
        alterVariables: Function,
        alterOptions: Function,
        editItemAction: {
            type: Function,
            default: () => { }
        },
        reportAction: {
            type: Function,
            default: () => { }
        },
        treeview: {
            type: Object,
            default: () => ({})
        }
    },
    data: () => ({
        totalItems: 0,
        items: [],
        loading: false,
        expanded: [],
        options: {},
        cancelRequest: null,
        childrenLoading: false
    }),
    computed: {
        visibleItems() {
            let items = [];
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                if (!item.hasOwnProperty('treeVisible') || item.treeVisible == true) {
                    items.push(item);
                }
            }
            return items;
        }
    },
    watch: {
        options: {
            handler() {
                localStorage.setItem('options-' + this.config.index.endpoint, JSON.stringify(this.options));
                this.getDataFromApi();
            },
            deep: true,
        },
    },
    mounted() {
        let options = localStorage.getItem('options-' + this.config.index.endpoint);
        if (options) {
            this.options = JSON.parse(options);
            if (this.options.itemsPerPage < 0) {
                this.options.itemsPerPage = 100;
            }
        }
    },
    methods: {
        toggleChildren(itemId) {

            if (!this.childrenLoading) {
                this.childrenLoading = true;

                let parentItemIndex = null;
                for (let i = 0; i < this.items.length; i++) {
                    const item = this.items[i];
                    if (item.id == itemId) {
                        parentItemIndex = i;
                    }
                }

                if (!this.items[parentItemIndex].hasOwnProperty('treeCollapsed') || this.items[parentItemIndex].treeCollapsed) {
                    if (this.items[parentItemIndex].treeLoaded) {
                        for (let i = 0; i < this.items.length; i++) {
                            const item = this.items[i];
                            if (item.treeParentId == itemId) {
                                item.treeVisible = true;
                            }
                        }

                        this.$nextTick(() => {
                            this.$forceUpdate();
                        });
                        this.items[parentItemIndex].treeCollapsed = false;
                        this.childrenLoading = false;
                    } else {
                        this.$eod.get(this.config.index.endpoint, this.config.index.fields, {
                            where: [
                                {
                                    column: 'parentProductId',
                                    operator: '=',
                                    value: itemId
                                }
                            ]
                        })
                            .then(response => {

                                if (response.data.data[this.config.index.endpoint].edges) {
                                    let childItems = response.data.data[this.config.index.endpoint].edges;
                                    // Insert items after parent
                                    const parentItem = this.items[parentItemIndex];
                                    this.items[parentItemIndex].treeCollapsed = false;
                                    this.items[parentItemIndex].treeLoaded = true;
                                    for (let j = 0; j < childItems.length; j++) {
                                        childItems[j].treeLevel = parentItem.treeLevel ? (parentItem.treeLevel + 1) : 1;
                                        childItems[j].treeVisible = true;
                                        childItems[j].treeParentId = itemId;
                                        this.items.splice(parentItemIndex + 1, 0, childItems[j]);
                                    }
                                    this.$nextTick(() => {
                                        this.$forceUpdate();
                                    });
                                }
                            }).finally(() => {
                                this.childrenLoading = false;
                            });
                    }
                } else {
                    const itemLevel = this.items[parentItemIndex].treeLevel ? this.items[parentItemIndex].treeLevel : 0;
                    for (let i = parentItemIndex + 1; i < this.items.length; i++) {
                        const item = this.items[i];
                        if (item.treeLevel > itemLevel) {
                            this.items[i].treeCollapsed = true;
                            item.treeVisible = false;
                        } else {
                            break;
                        }
                    }

                    this.items[parentItemIndex].treeCollapsed = true;
                    this.childrenLoading = false;

                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                }


            }

        },
        droppedItem(itemId, newParentId) {
            let saveData = {
                id: itemId
            };

            saveData[this.treeview.parentIdProperty] = newParentId ? newParentId : null;

            this.$eod.save(this.config.detail.objectName, saveData)
                .then(() => {
                    this.getDataFromApi();
                });
        },
        updatedStatus(taskId, statusCode) {
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                if (item.id == taskId) {
                    this.items[i].statusCode = statusCode;
                }
            }
        },
        getQueryVariables() {
            let variables = {
                isActive: false
            };
            if (this.options.sortBy && this.options.sortBy[0]) {
                variables.orderBy = {
                    column: this.options.sortBy[0],
                    type: this.options.sortDesc[0] ? 'desc' : 'asc'
                }
            } else {
                variables.orderBy = {
                    column: 'createdAt',
                    type: 'desc'
                }
            }

            let filterVariables = this.$helper.filtersToVariables(this.filters);

            variables = {
                ...variables,
                ...filterVariables
            };

            if (this.timeFilters) {
                if (!variables.where) {
                    variables.where = [];
                }

                variables.where.push({
                    column: 'createdAt',
                    operator: '>=',
                    value: this.timeFilters.from.toISOString()
                });
                variables.where.push({
                    column: 'createdAt',
                    operator: '<=',
                    value: this.timeFilters.until.toISOString()
                });
            }

            if (this.alterVariables) {
                variables = this.alterVariables(variables);
            }

            return variables;
        },
        getDataFromApi(options) {

            if (this.cancelRequest) {
                this.cancelRequest.cancel();
            }

            this.loading = true;

            let variables = this.getQueryVariables();

            if (options) {
                this.options = {
                    ...this.options,
                    ...options
                };
            }

            if (this.options.page) {
                variables.offset = this.options.page - 1;
            }
            if (this.options.itemsPerPage && this.options.itemsPerPage >= 0) {
                variables.limit = this.options.itemsPerPage;
            }

            if (this.alterOptions) {
                variables = this.alterOptions(variables);
            }

            if (this.treeview && this.treeview.show && this.treeview.parentIdProperty) {
                // TODO: Keep open rows and request them with whereIn
                // ...

                // 
                variables.whereNull = {
                    column: this.treeview.parentIdProperty,
                };
            }

            this.items = [];

            this.cancelRequest = this.$eod.getCancelRequest();

            let extra = {
                cancelToken: this.cancelRequest.token
            };

            return this.$eod.get(this.config.index.endpoint, this.config.index.fields, variables, extra).then(async (result) => {

                let items = [];
                this.totalItems = 0;

                if (result.data.data && result.data.data[this.config.index.endpoint]) {
                    items = result.data.data[this.config.index.endpoint].edges;
                    this.totalItems = result.data.data[this.config.index.endpoint].totalCount;

                    if (this.prepareItems) {
                        items = await this.prepareItems(items);
                    } else if (this.config.itemObject) {
                        for (let i = 0; i < items.length; i++) {
                            items[i] = new (this.config.itemObject)(items[i]);
                        }
                    }
                }

                this.items = items;


                this.$emit('loaded', this.items);

                this.$nextTick(() => {
                    this.loading = false;
                });

                return this.items;


            }).catch(e => {
                if (e.__CANCEL__) {
                    this.$log.info('Previous request aborted.', e);
                } else {
                    this.loading = false;
                    this.$log.error('Error loading items!', e);
                }
            });
        }
    }
}
</script>
<template>
    <div>
<eod-text-field
                                :value="value.name"
                                :rules="[val => $validator.required(val, 'Naam')]"
                                @input="val => updateProperty('name', val)"
                                label="Naam*"
                                required
                                outlined
                                hide-details
                                :disabled="value.isReadonly"
                            ></eod-text-field>
                            <eod-text-field
                                :value="value.description"
                                label="Omschrijving"
                                @input="val => updateProperty('description', val)"
                                outlined
                                hide-details
                                class="mt-4"
                                :disabled="value.isReadonly"
                            ></eod-text-field>
                        </div>
</template>
<script lang="js">
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
export default {
    name: 'eod-tool-type-detail',
    components: {
        eodListItemAutocomplete
    },
    props:{
        value: Object
    },
    methods:{
        updateProperty(propertyName, value){
            const val = this.value;
            val[propertyName] = value;

            this.$emit('input', val);
        }
    }
}
</script>
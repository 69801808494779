<template>
    <div class="d-flex">
        <eod-autocomplete :disabled="readonly" v-model="type" @change="changeType" :items="[{value:'+', text:'Geproduceerd'}, {value:'-', text:'Verbruikt'}]"></eod-autocomplete>
        <eod-text-field label="Aantal" type="number" :value="value?Math.abs(value.quantity):null" @input="updateQuantity" :disabled="readonly"></eod-text-field>
    </div>
</template>
<script>
export default {
    name: 'eod-article-stock-movement',
    props:{
        cell: Object,
        value: Object,
        readonly: Boolean
    },
    data:() => ({
        type: '-'
    }),
    created(){
        if(this.value && this.value.quantity){
            if(this.value.quantity < 0){
                this.type = '-';
            } else {
                this.type = '+';
            }
        }
    },
    methods:{
        updateQuantity(quantity){
            const val = this.value
            if(this.type == '+'){
                val.quantity = quantity;
            } else {
                val.quantity = quantity * -1;
            }

            this.$emit('input', val);
        },
        changeType(type){
            const val = this.value;
            if(type == '+'){
                val.quantity = Math.abs(val.quantity);
            } else {
                val.quantity = val.quantity * -1;
            }

            this.$emit('input', val);
        }
    }
}
</script>
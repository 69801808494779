<template>
    <div>
        <v-row>
        <v-col cols="auto" style="min-width:25%;">
          <v-card>
            <v-card-text style="overflow-y: auto;max-height:calc(100vh - 260px);">
              <eod-objects-treeview :search="search" @context-add-folder="addFolder" @delete="deleteItem" @context-add="addItem" @context-move="moveItem" :classes="classes" :selectable="[className, className+'_FOLDER']" ref="treeview" :selected="selectedItemIds" @selected="selectionChanged">
                <template v-slot:item-extra="{item}">
                    <slot name="item-extra" :item="item">
                        <div class="px-4">{{ item.typeName }}</div>
                    </slot>
                </template>
                
              </eod-objects-treeview>
              <eod-dialog v-model="isMoveDialogVisible" title="Item verplaatsen" icon="mdi-file-move-outline" :loading="isMoveDialogLoading" iconColor="primary" width="800" okText="Verplaatsen" @okAction="moveItemAction">
                <eod-search class="mt-4" :config="Product.searchConfig" v-model="searchFilters" @update="updateSearch"></eod-search>
                <eod-objects-treeview ref="treeviewMove" multiple readonly :search="$helper.filtersToVariables(searchFilters)" :classes="classes" :selectable="[className, className+'_FOLDER']" @selectionChanged="moveSelectionChanged"></eod-objects-treeview>
              </eod-dialog>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card :loading="isItemLoading">
            <v-card-text>
                <slot name="detail" v-if="selectedItemIds[0]" v-bind:reload="reloadTree" v-bind:loading="isItemLoading" v-bind:item="selectedItemIds[0].id?selectedItemIds[0].id:selectedItemIds[0]">
                    <eod-product-detail @saved="reloadTree" @loading="val => {isItemLoading=val;}" :value="selectedItemIds[0].id?selectedItemIds[0].id:selectedItemIds[0]"></eod-product-detail>
                </slot>
              <div v-else>Selecteer een item om gegevens te bekijken.</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <eod-delete-dialog v-if="isDeleteDialogVisible" v-model="isDeleteDialogVisible" :item="itemToDelete" @delete="deleteItemAction">
        Weet u zeker dat u <strong>{{ itemToDelete.name }}</strong> wilt verwijderen?
        <v-alert class="mt-2 mb-0" dense v-if="itemToDelete.productsCount" color="error" text>
        <v-icon color="error" left>mdi-exclamation</v-icon>Alle onderliggende items worden ook verwijderd!
        </v-alert>
      </eod-delete-dialog>
    </div>
</template>
<script>
import eodObjectsTreeview from './eod-objects-treeview.vue';
import eodProductDetail from './eod-product-detail.vue';
import eodDeleteDialog from './eod-delete-dialog.vue';
import eodDialog from './eod-dialog.vue';
import eodSearch from './eod-search.vue';
import Product from './../models/product';

export default {
    name: 'eod-objects-treeview-detail',
    components:{
        eodObjectsTreeview,
        eodProductDetail,
        eodDeleteDialog,
        eodDialog,
        eodSearch
    },
    props:{
        search: Object,
        className: {
            type: String,
            default: 'DEVICE'
        },
        objectName: String,
        openItemsIds: Array,
        classes: Array,
    },
    data: () => ({
        Product:Product,
        searchFilters:[],
        selectedItemIds: [],
        moveSelection: [],
        isItemLoading:false,
        itemToDelete:null,
        isDeleteDialogVisible:false,
        moveToItemObject: null,
        isMoveDialogVisible:false,
        isMoveDialogLoading:false,
    }),
    created(){
        if(this.openItemsIds){
            this.selectedItemIds = this.openItemsIds;
        }
    },
    methods:{
        async moveItemAction(){
            this.isMoveDialogLoading = true;
            
            for (let i = 0; i < this.moveSelection.length; i++) {
                const selectedItem = this.moveSelection[i];
                await this.$eod.save(this.objectName, {
                    id: selectedItem.id,
                    treeParentId: this.moveToItemObject.id
                });
            }
            
            this.isMoveDialogLoading = false;
            this.isMoveDialogVisible = false;

            if(this.$refs.treeview){
                this.$refs.treeview.fetchItems();
            }
        },
        updateSearch(){
            this.$nextTick(() => {
                if(this.$refs.treeviewMove){
                    this.$refs.treeviewMove.fetchItems();
                }
            })
        },
        reloadTree(itemId){
            if(itemId){
                this.selectedItemIds = [{id:itemId}];
            }else{
                this.selectedItemIds = [];
            }

            if(this.$refs.treeview){
                this.$refs.treeview.fetchItems();
            }
        },
        moveSelectionChanged(selection){
            this.moveSelection = selection;
        },
        selectionChanged(item){
            if(this.selectedItemIds.map(item => {return item.id}).includes(item.id)){
                return false;
            }

            this.selectedItemIds = [];
            this.$nextTick(() => {
                const query = this.$route.query;
                query['productIds[]'] = [item.id];
                const routeVar = {name:this.$route.name, params:this.$route.params, query:query};
                const route = this.$router.resolve(routeVar).route;
                window.history.pushState({}, null, route.fullPath);

                this.selectedItemIds.push(item);
            });
        
        },
        addFolder(parent){
            this.selectedItemIds = [];
            this.$nextTick(() => {
                const data = {
                class: this.className + '_FOLDER',
                };

                if(parent){
                data.treeParentId = parent.id;
                }
                this.selectedItemIds = [data];
            });
        },
        addItem(parent){
            this.selectedItemIds = [];
            this.$nextTick(() => {
                const data = {
                    class: this.className,
                };

                if(this.className.toLowerCase() == 'device'){
                    data['productType'] = {};
                    data['statusCode'] = {};
                } else {
                    data[this.className.toLowerCase()+'Type'] = {};
                }

                

                if(parent){
                    data.treeParentId = parent.id;
                }
                this.selectedItemIds = [data];
            });
        },
        moveItem(parent){
            this.moveSelection = [];
            this.searchFilters = [];
            this.moveToItemObject = parent;
            this.isMoveDialogVisible=true;
        },
        deleteItem(item){
            this.itemToDelete = item;
            this.isDeleteDialogVisible = true;
        },
        deleteItemAction(){
            this.$eod.delete(this.objectName, this.itemToDelete.id).then(() => {
                this.reloadTree();
            });
        },
    }
}
</script>
<template>
    <div class="h-100 w-100">
        <v-chart v-show="value.length > 0" @finished="finished" :ref="id" :initOptions="graphOptions" :autoresize="true" :manualUpdate="true" />
        <div v-if="!value">Gelieve de grafiek in te stellen</div>
        <div v-else-if="value.length == 0">Geen waardes gevonden</div>
    </div>
</template>
<style lang="scss">
.echarts {
    width: 100%;
    height: 100%;
}
</style>
<script>
import ECharts from './echarts/ECharts';
import moment from 'moment';

export default {
    name: 'eod-graph-heatmap',
    components: {
        'v-chart': ECharts,
    },
    props: {
        id: String,
        value: Object | Array,
        cell: Object,
        template: Object | Array,
        xAxis: Object | Array,
        yAxis: Object | Array,
        filters: Array,
        group: String,
        options: {
            type: Object,
            default() {
                return {}
            }
        },
        defaultSerie: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data: () => ({
        graphOptions: {},
        defaultOptions: {
            //renderer: 'svg',
            grid: {
                left: '5%',
                top: 0, // Needed for legend
                right: '5%',
                bottom: 50,
                containLabel: true
            },
            xAxis: {
                type: 'category',
                axisLabel: {
                    formatter: val => moment(val).format('DD/MM')
                },
                data: [],
                splitArea: {
                    show: true
                }
            },
            yAxis: {
                type: 'category',
                data: [],
                splitArea: {
                    show: true
                }
            },
            tooltip: {},
            visualMap: {
                min: 0,
                max: 10,
                calculable: true,
                orient: 'horizontal',
                left: 'center',
                bottom: '0'
            },
            series: [],
        }
    }),
    computed: {
        groupName() {
            if (this.group) {
                return this.group;
            }
            return 'eod-graph-line'; //template.content.group.key+'_'+template.content.group.value;
        }
    },
    methods: {
        finished() {
            this.$emit('finished', this.id, this.$refs[this.id]);
        },
        resize() {
            this.$refs[this.id].resize();
        },
        measurementTypeFieldName(index){
            if (this.template[index]) {
                        const content = this.template[index].content;
                        for (let i = 0; i < content.measurementTypes.length; i++) {
                            const aggMeasurementType = content.measurementTypes[i];
                            return aggMeasurementType.aggregation+'_'+aggMeasurementType.object.id;
                        }
                    }
            return null;
        },
        async update() {

            this.graphOptions = this.defaultOptions;
            this.graphOptions.series = []

            if(this.cell && this.cell.settings){
                if(this.cell.settings.min && !isNaN(this.cell.settings.min)){
                    this.graphOptions.visualMap.min = parseFloat(this.cell.settings.min);
                }
                if(this.cell.settings.max && !isNaN(this.cell.settings.max)){
                    this.graphOptions.visualMap.max = parseFloat(this.cell.settings.max);
                }
            }

            // Set xAxis (time)
            // Get all timestamps (can be different per serie)
            this.graphOptions.xAxis.data = [];
            this.graphOptions.yAxis.data = [];
            const allTimeStamps = [];
            const allHours = [];
            for (let i = 0; i < this.value.length; i++) {
                const serie = this.value[i];
                for (let j = 0; j < serie.data.length; j++) {
                    const dataItem = serie.data[j];
                    const xAxisFormatted = moment(dataItem.time_interval).format('YYYY-MM-DD');
                    if(!allTimeStamps.includes(xAxisFormatted)){
                        allTimeStamps.push(xAxisFormatted);
                    }
                    if(this.cell && this.cell.settings && this.cell.settings.yAxis.groupBy){
                        const yAxisFormatted = moment(dataItem.time_interval).format('HH:mm');
                        if(!allHours.includes(yAxisFormatted)){
                            allHours.push(yAxisFormatted);
                        }
                    }
                }
            }

            if(this.cell && this.cell.settings && this.cell.settings.yAxis.groupBy){
                allHours.sort(function(a, b){
                    return Date.parse('1970/01/01 ' + b) - Date.parse('1970/01/01 ' + a);
                });
                this.graphOptions.yAxis.data = allHours;
            }

            allTimeStamps.sort();
            this.graphOptions.xAxis.data = allTimeStamps;

            let series = [];
            for (let i = 0; i < this.value.length; i++) {
                const value = this.value[i];
                if (value) {

                    let serie = {
                        name: '',
                        data: [],
                        type: 'heatmap',
                        label:{
                            show:false
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    };

                    // const measurementTypeId = Object.keys(value.measurementTypes)[0];
                    // const measurementType = value.measurementTypes[measurementTypeId];

                    if (this.defaultSerie) {
                        serie = {
                            ...serie,
                            ...this.defaultSerie
                        }
                    }

                    if (this.cell) {
                        serie = {
                            ...serie,
                            ...this.cell.settings.queries[value.queryIndex].options ? this.cell.settings.queries[value.queryIndex].options : {}
                        }
                    }

                    serie.name = value.name;

                    if(!this.cell || !(this.cell && this.cell.settings && this.cell.settings.yAxis.groupBy)){
                        this.graphOptions.yAxis.data.push(value.name);
                    }

                    const fieldname = this.measurementTypeFieldName(value.queryIndex);

                    for (let j = 0; j < value.data.length; j++) {
                        const data = value.data[j];
                        if (data) {
                            const xPos = this.graphOptions.xAxis.data.indexOf(moment(data.time_interval).format('YYYY-MM-DD'));
                            let yPos = i;
                            if(this.cell && this.cell.settings && this.cell.settings.yAxis.groupBy){
                                yPos = this.graphOptions.yAxis.data.indexOf(moment(data.time_interval).format('HH:mm'));
                            }
                            serie.data.push([xPos, yPos, data[fieldname]]);
                        }
                    }

                    series[i] = serie;
                }
            }

            this.graphOptions.series = series;
            if (this.$refs[this.id]) {
                this.$refs[this.id].mergeOptions(this.graphOptions);
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            } else {
                console.error('graph not found');
            }

        }
    }
}
</script>
<template>
    <div>
    <div class="d-flex px-4 py-2" v-if="(!readonly && !hideAdd) || !hideSearch">
        <eod-text-field
        v-if="!hideSearch"
        v-model="search"
        append-icon="mdi-magnify"
        label="Zoeken"
        single-line
        hide-details
      ></eod-text-field>
        <v-spacer></v-spacer>
        <v-btn v-if="!readonly && !hideAdd" color="primary" outlined @click="addItem"><v-icon>mdi-plus</v-icon> Toevoegen</v-btn>
    </div>
<v-data-table
                :headers="headers"
                :items="value?value.map((item, index) => ({  ...item,  id: index})):[]"
                :items-per-page="-1"
                :search="search"
                locale="nl-BE"
                :expanded.sync="expanded"
                item-key="id"
                >
                <template v-slot:item="{item}">
                <tr>
                    <td @click="editItem(item)">
                        {{item.name}}
                    </td>
                    <td @click="editItem(item)">
                        {{item.description}}
                    </td>
                    <td @click="editItem(item)">
                        {{item.streetAddress}}, {{item.postalCode}} {{item.locality}}
                    </td>
                    <td>
                        <eod-column-actions v-if="!readonly" :item="item" :deleteAction="deleteItem" :editAction="editItem" :show-copy="false"></eod-column-actions>
                    </td>
                </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-row no-gutters>
                            <v-col cols="12" md="4" xl="3">
                                <eod-location-form :value="value[item.id]" @update="val => updateAddress(item.id, val)"></eod-location-form>
                            </v-col>
                            <v-col cols="12" md="8" xl="9">
                                <v-card tile flat color="#FBFBFB" class="h-100">
                            <v-card-text>
                                <fieldset class="mb-2">
                                    <legend>Adres <v-tooltip top><template v-slot:activator="{on, attrs}"><v-icon v-on="on" v-bind="attrs" x-small>mdi-information</v-icon></template>Vul deze gegevens automatisch in via het zoekvenster links</v-tooltip></legend>
                                <v-row>
                                <v-col cols="6">
                                    <eod-text-field :value="value[item.id].streetAddress" @input="val => updateProperty('streetAddress', item.id, val)" label="Straat + nummer"></eod-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <eod-text-field :value="value[item.id].postalCode" @input="val => updateProperty('postalCode', item.id, val)" label="Postcode"></eod-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <eod-text-field :value="value[item.id].locality" @input="val => updateProperty('locality', item.id, val)" label="Stad/Gemeente"></eod-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <eod-text-field :value="value[item.id].region" @input="val => updateProperty('region', item.id, val)" label="Regio"></eod-text-field>
                                </v-col>
                                <v-col>
                                    <eod-text-field :value="value[item.id].country" @input="val => updateProperty('country', item.id, val)" label="Land"></eod-text-field>
                                </v-col>
                            </v-row>
                        </fieldset>
                        <fieldset>
                            <legend>Info</legend>
                            <v-row>
                                <v-col>
                                    <eod-text-field :value="value[item.id].name" :rules="nameRules" @input="val => updateProperty('name', item.id, val)" label="Naam"></eod-text-field>
                                </v-col>
                                <v-col>
                                    <eod-text-field :value="value[item.id].description" @input="val => updateProperty('description', item.id, val)" label="Omschrijving" info="Beknopte omschrijving om een locatie te identificeren"></eod-text-field>
                                </v-col>
                                <v-col v-if="showDepartment">
                                    <eod-listitem-autocomplete v-if="$eod.getOrganizationSetting('companies.departments.listId')" v-model="value[item.id].department" label="Departement" returnObject :listId="$eod.getOrganizationSetting('companies.departments.listId').value"></eod-listitem-autocomplete>
                                </v-col>
                            </v-row>
                            
                            <eod-textarea class="mt-4" :value="value[item.id].notes" @input="val => updateProperty('notes', item.id, val)" label="Opmerkingen" info="Extra informatie om relevante details te delen die nuttig zijn voor andere gebruikers of als logboek voor toekomstige referentie.<br>Deze opmerkingen worden in het oranje bij de taak getoond in de planning en de mobiele applicatie"></eod-textarea>
                        </fieldset>
                            
                            </v-card-text>
                        </v-card>
                            </v-col>
                        </v-row>
                        
                    </td>
                </template>
                </v-data-table>
            </div>
</template>

<script>
import eodColumnActions from './columns/eod-column-actions.vue';
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import eodLocationForm from './eod-location-form.vue';

export default {
  components: {
      eodColumnActions,
      eodListItemAutocomplete,
      eodLocationForm
  },
  props: {
      value: Array,
      hideSearch:Boolean,
      hideAdd:Boolean,
      readonly:Boolean,
      showDepartment: {
          default: false,
          type: Boolean
      }
  },
  data(){
      return {
        nameRules: [
            v => !!v || 'Naam is verplicht',
        ],
        expanded: [],
        search: '',
        headers: [
            { text: 'Naam', value: 'name'},
            { text: 'Omschrijving', value: 'description'},
            { text: 'Adres', value: 'streetAddress'},
            { width: 120, sortable:false, value: 'actions'},
        ]
      }
  },
  computed:{
      itemsWithIndex(){
          let items = [];
          if(this.value){
              for (let i = 0; i < this.value.length; i++) {
                let item = Object.assign({}, this.value[i]);
                item.id = i;
                items.push(item);           
            }
          }
          
          return items;
      }
  },
  methods: {
    updateProperty(propertyName, id, value){
        const val = this.value;
        val[id][propertyName] = value;
        this.$emit('input', val);
    },
    updateAddress(id, address){

        if(!address){
            return false;
        }

        const val = this.value;

        if(address.road){
            val[id].streetAddress = address.road;

            if(address.house_number){
                val[id].streetAddress += ' ' + address.house_number;
            }
        }
        
        if(address.country){
            val[id].country = address.country;
        }

        if(address.city){
            val[id].locality = address.city;
        }
        if(address.town){
            val[id].locality = address.town;
        }
        if(address.village){
            val[id].locality = address.village;
        }
        
        if(address.postcode){
            val[id].postalCode = address.postcode;
        }

        if(address.state){
            val[id].region = address.state;
        }

        this.$emit('input', val);

        this.$nextTick(() => {
            this.$forceUpdate();
        })
        
    },
      deleteItem(item){
        const val = this.value;
          val.splice(item.id, 1);
          this.$emit('input', val);
      },
      editItem(item){
        if(this.readonly){
            return false;
        }
          // If index, get item
          if (!isNaN(item)) {
              item = {id: item}
          }

          if (this.expanded.includes(item)) {
              this.expanded = [];
          }else{
              this.expanded = [item];
          }
      },
      addItem(){
        let val = this.value;
        
          if (!val) {
              val = [];
          }

          val.push({
              name: 'Nieuw',
          });

          this.$emit('input', val);

          this.$nextTick(() => {
            this.editItem(this.value.length-1);
          });
      }
  }
}
</script>

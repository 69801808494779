<template>
    <td>
      <eod-button-copy v-if="showCopy" :value="item"></eod-button-copy>
     
        <v-tooltip top v-if="showEdit">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem">
                    <v-icon small v-bind="attrs" v-on="on">mdi-pencil-outline</v-icon>
                </v-btn>
            </template>
            Aanpassen
        </v-tooltip>

        <v-tooltip top v-if="showDuplicate">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="$emit('duplicate')">
                    <v-icon small v-bind="attrs" v-on="on">mdi-content-duplicate</v-icon>
                </v-btn>
            </template>
            Dupliceren
        </v-tooltip>

       <v-dialog v-model="deleteDialog" persistent max-width="550" v-if="showDelete && !readonly">
            <template v-slot:activator="{ on: dialog, attrs }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn class="error--text" icon v-bind="attrs" v-on="{ ...tooltip, ...dialog}">
                        <v-icon small>{{deleteIcon}}</v-icon>
                      </v-btn>
                    </template>
                    {{deleteText}}
                </v-tooltip>
            </template>
            <v-card :loading="isDeleteLoading">
                <v-card-title class="mb-4">
                  <v-avatar color="error" size="40" class="elevation-3 mr-4">
            <v-icon dark>
              {{deleteIcon}}
            </v-icon>
          </v-avatar><span class="headline">{{deleteText}}?</span>
          </v-card-title>
                <v-card-text>
                  <span class="text-body-1">Weet u zeker dat u '<strong>{{getTitle()}}</strong>' wilt {{deleteText.toLowerCase()}}?</span>
                  <template v-if="related && related.length > 0">
                    <br>
                    <span class="text-subtitle-1">Volgende gekoppelde items worden ook verwijderd.</span>
                    <v-expansion-panels class="mt-2">
                      <v-expansion-panel v-for="link in related" :key="link.key">
                        <v-expansion-panel-header class="p-0">
                          {{$t($helper.getModuleNameByObjectType(link.key))}}
                          <template v-slot:actions>
                            <v-chip color="error lighten-2" small class="mr-2">{{link.items.length}}</v-chip>
                            <v-icon>
                              $expand
                            </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list dense>
                            <v-list-item v-for="linkedItem in link.items" :key="linkedItem.id">
                              <v-list-item-content>
                                <v-list-item-title>{{linkedItem.name}}</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action v-if="link.key != 'response'"><v-btn icon small :to="{name:link.key, params:{id:linkedItem.id}}" target="_blank"><v-icon small>mdi-eye</v-icon></v-btn></v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </v-card-text>
                <v-card-actions class="grey lighten-3 py-3 px-3">
                <v-spacer></v-spacer>
                <v-btn text @click="deleteDialog = false" rounded>Annuleren</v-btn>
                <v-btn color="error" depressed rounded @click="deleteItem">
                    <v-icon left>{{deleteIcon}}</v-icon> {{deleteText}}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <v-dialog
      v-model="errorDialog"
      max-width="360"
       v-if="showDelete && !readonly"
    >
      <v-card>
        <v-card-title class="headline">Fout bij {{deleteText.toLowerCase()}}</v-card-title>
        <v-card-text>
          Er liep iets fout bij het {{deleteText.toLowerCase()}} van dit object. Gelieve later opnieuw te proberen of contacteer de beheerder.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="showDelete = false"
            text
          >
            Sluiten
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </td>
</template>
<script>
import eodButtonCopy from './../eod-button-copy.vue';

    export default {
        name:'eod-column-actions',
        components: {
          eodButtonCopy
        },
        props:{
            item: Object,
            config: Object,
            extra: Object,
            editAction: Function,
            deleteAction: Function,
            deleteIcon: {
              type: String,
              default: 'mdi-trash-can-outline'
            },
            deleteText: {
              type: String,
              default: 'Verwijderen'
            },
            showEdit: {
              type: Boolean,
              default: true
            },
            showDelete: {
              type: Boolean,
              default: true
            },
            readonly: {
              type: Boolean,
              default: true
            },
            showCopy: {
              type: Boolean,
              default: true
            },
            showDuplicate: Boolean,
            valueText: Function
        },
        data(){
            return {
                deleteDialog: false,
                errorDialog: false,
                related: null,
                isDeleteLoading:false,
                copySuccess:false,
                copyError:false,
            }
        },
        watch:{
          deleteDialog(val){
            if (val && !this.related && this.config && (this.extra && !this.extra.hideRelated)) {
              this.isDeleteLoading = true;
              this.$eod.getRelated(this.config.detail.objectName, this.item.id).then(result => {
                this.related = [];
                if (result.data.data['related'+this.config.detail.objectName+'Objects']) {
                  let items = result.data.data['related'+this.config.detail.objectName+'Objects'];
                  for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    let existingIndex = this.getRelatedIndex(item.type);
                    if (existingIndex == null) {
                      this.related.push({
                        key: item.type,
                        items: [{id:item.id, name:item.name}]
                      });
                    }else{
                      this.related[existingIndex].items.push({id:item.id, name:item.name});
                    }
                  }
                }
              }).finally(() => {
                this.isDeleteLoading = false;
              });
            }
          }
        },
        methods: {
          onCopySuccess(){
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
                }, 1000);
                
            },
            onCopyError(){
                this.copyError = true;
                setTimeout(() => {
                    this.copyError = false;
                }, 1000);
            },
          getRelatedIndex(type){
            if (this.related) {
              for (let i = 0; i < this.related.length; i++) {
                const related = this.related[i];
                if (related.key == type) {
                  return i;
                }
              }
            }

            return null;
          },
          getTitle(){
            if (this.valueText) {
              return this.valueText(this.item);
            }

            return this.item.name;
          },
          editItem(){
            if(this.editAction){
              this.editAction(this.item);
            }else if(this.extra && this.extra.editAction){
              this.extra.editAction(this.item);
            }else{
              this.$router.push({name: this.config.detail.route, params: {id: this.item.id}});
            }
          },
            deleteItem(){
              if(this.deleteAction){
                this.deleteAction(this.item, () => {
                  this.deleteDialog = false;
                  this.$emit('update');
                });
              }else{
                this.$eod.delete(this.config.detail.objectName, this.item.id).then(response => {
                  this.$toaster.notify({
                    type: 'success',
                    title: 'Item verwijderd',
                    body: 'Item is succesvol verwijderd!'
                  });
                    this.deleteDialog = false;
                    this.$emit('update');
                }).catch(error => {
                    if (error.response.data.message) {
                        let messages = JSON.parse(error.response.data.message);
                    }
                    this.errorDialog = true;
                });
              }
            }
        }
    }
</script>